import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  constructor(
    private _title: Title,
    private _meta: Meta
  ) { }

  ngOnInit(): void {
    this.setTittle();
  }

  setTittle(){
    // console.warn('setTittle');
    this._title.setTitle('DekThai Online ศูนย์รวมติวเตอร์คุณภาพเพื่อเด็กไทย');
    this._meta.addTags([
      { name: 'og:type', content: 'article' },
      { name: 'og:url', content: 'dekthai-online.com' },
      { name: 'og:title', content: 'DekThai Online ศูนย์รวมติวเตอร์คุณภาพเพื่อเด็กไทย' },
      { name: 'og:description', content: 'DekThai-Online ระบบที่ครบถ้วนด้วยคุณภาพเนื้อหา ราคา เพื่อเด็กไทยโดยเฉพาะ' },
      { name: 'og:image', content: 'https://assets.dekthai-online.com/static/dekthai-icon-mini.jpg' },
      { property: 'og:type', content: 'article' },
      { property: 'og:url', content: 'dekthai-online.com' },
      { property: 'og:title', content: 'DekThai Online ศูนย์รวมติวเตอร์คุณภาพเพื่อเด็กไทย' },
      { property: 'og:description', content: 'DekThai-Online ระบบที่ครบถ้วนด้วยคุณภาพเนื้อหา ราคา เพื่อเด็กไทยโดยเฉพาะ' },
      { property: 'og:image', content: 'https://assets.dekthai-online.com/static/dekthai-icon-mini.jpg' }
    ]);
  }


}

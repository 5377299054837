import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';

import { URLAPI, HTTPOPTION } from '../../app.conn';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styles: [
  ]
})
export class PurchaseComponent implements OnInit {

  constructor(
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _title: Title,
    private _meta: Meta
  ) { }

  URLAPI:string;
  HTTPOPTION:any;

  ops_course_id;
  course;

  ngOnInit(): void {
    this.URLAPI = URLAPI;
    this.HTTPOPTION = HTTPOPTION;

    let sub = this._route.params.subscribe(params => {
      this.ops_course_id = params['ops_course_id'];
    });

    this.getCourse();
  }

  async getCourse(){
    console.warn('ops_course_id: '+this.ops_course_id);

    let api = 'g/course'
    let params = '/' + this.ops_course_id;
    let url = this.URLAPI+api+params;

    let response = await this._http
      .get(url, this.HTTPOPTION)
      .pipe()
      .toPromise();
      console.warn(response);

      let course = response['course'];
      this.course = course;

  }

}

<div class="container-fuild">
    <div class="container">

        <div class="row">

            <div class="col-12 col-sm-12 col-md-3 col-lg-3" style="display: none;">

                <ul class="nav flex-column">
                    <li class="nav-item">
                      <a class="btn btn-light active btntab" >คอร์สของฉัน</a>
                    </li>
                    <li class="nav-item">
                      <a class="btn btn-light btntab" >ประวัติการ Quiz</a>
                    </li>
                    <li class="nav-item">
                      <a class="btn btn-light btntab" >ประวัติการ Test</a>
                    </li>
                    <li class="nav-item">
                      <a class="btn btn-light btntab" >ประวัติการ Retest</a>
                    </li>
                    <li class="nav-item">
                      <a class="btn btn-light btntab" >ประวัติการเรียน</a>
                    </li>

                  </ul>

            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-12">

                <!-- <app-notify></app-notify> -->
                <app-mycourse></app-mycourse>
                <app-history></app-history>

            </div>

        </div>
    </div>
</div>



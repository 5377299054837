import { Component, OnInit } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ConnService } from '../../conn.service';
import { URLAPI, HTTPOPTION } from '../../app.conn';

@Component({
  selector: 'app-courseimport',
  templateUrl: './courseimport.component.html',
  styles: [
  ]
})
export class CourseimportComponent implements OnInit {

  constructor(
    private _formb: FormBuilder,
    private _http: HttpClient,
    private _conn: ConnService
  ) { }
  courseImport = this._formb.group({
    ops_course_id: ['', Validators.required]
  });

  URLAPI:string;
  HTTPOPTION;

  authorizationData;

  ngOnInit(): void {
    this.authorizationData = this._conn.authorizationData();
    this.URLAPI = URLAPI;
    this.HTTPOPTION = HTTPOPTION;
  }

  course = {name:''};
  status = false;

  async updatePrice(){


    let api = 'a2/priceUpdate'
    let url = this.URLAPI+api;

    let response = await this._http
      .get(url, this.HTTPOPTION)
      .pipe()
      .toPromise();

        alert('Update Price Complete');
  }

  async onImport(){
    let api = 'a2/importCourseOPS';
    let url = this.URLAPI + api;

    let formData = this.courseImport.value;
    console.warn(formData);

    let httpOptions = {
      headers: new HttpHeaders({ 'content-type': 'application/json', 'authorization': this.authorizationData }),
      withCredentials: true
    };

    let response = await this._http
      .post(url, formData, httpOptions )
      .pipe()
      .toPromise();

      console.warn(response);
      let data = response['data'];
      if(data['status']){
        this.course = data['course'];
        this.status = data['status'];
        alert('เพิ่มคอร์สเรียบร้อยแล้ว แก้ไขรายละเอียดเพิ่มเติมด้วย');
      }else{
        this.course['name'] = 'เพิ่มคอร์สไม่ได้ หรือ มีคอร์สนี้ในเว็บใหม่แล้ว';
      }

    // this.course = response[];

  }

}

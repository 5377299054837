import { Component, OnInit } from '@angular/core';


import { ConnService } from '../../conn.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  // styles: [ './header.hidden.css' ],
  styleUrls: []
})
export class HeaderComponent implements OnInit {

  constructor(
    private _conn: ConnService,
  ) { }

  user;
  //userid:string = '0';
  is_login;

  ngOnInit(): void {


    // // this._conn.get();
    // this.user = this._cookieService.get('user');
    // this.userid = this._cookieService.get('userid');
    // //alert(this.userid);

    // if(this.userid == '0'){
    //   //alert('=0')
    //   this.is_login = false;
    // }else{
    //   this.is_login = true;
    // }

  }

}
<div class="container-fuild">


        <div class="row" style="margin-top: 20px;">

            <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <app-menu></app-menu>
            </div>
            <div class="col-12 col-sm-12 col-md-9 col-lg-9" style="min-height: 600px; border-left:1px solid #ccc;">
                <h5 style="border-bottom: 1px solid #ccc; padding-bottom: 15px;">
                    Index
                </h5>
            </div>

        </div>

</div>



import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MycourseComponent } from './mycourse/mycourse.component';
import { NotifyComponent } from './notify/notify.component';
import { HistoryComponent } from './history/history.component';
import { IndexComponent } from './index/index.component';

import { ProfileComponent } from './profile/profile.component';
import { QuizComponent } from './quiz/quiz.component';
import { TestsComponent } from './tests/tests.component';
import { SubscriberComponent } from './subscriber/subscriber.component';


const routes: Routes = [
  { path : 'dashboard', component : IndexComponent },
  { path : 'dashboard/profile', component : ProfileComponent },
  { path : 'dashboard/quiz', component : QuizComponent },
  { path : 'dashboard/test', component : TestsComponent },
  { path : 'dashboard/subscriber', component : SubscriberComponent },
//  {path : 'quizlists/:sid', component : QuizlistsComponent},
//  {path : 'quiz/:sid/:qid/:hash', component : QuizComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowseRoutingModule } from './browse-routing.module';

import { IndexComponent } from './index/index.component';
import { TextboxComponent } from './textbox/textbox.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ResultComponent } from './result/result.component';
import { CarouselComponent } from './carousel/carousel.component';


@NgModule({
  declarations: [IndexComponent, TextboxComponent, CheckboxComponent, ResultComponent, CarouselComponent],
  imports: [
    CommonModule,
    BrowseRoutingModule
  ]
})
export class BrowseModule { }

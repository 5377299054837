import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { FormControl, FormBuilder, Validators } from '@angular/forms';

import { ConnService } from './../conn.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    private _cookieService: CookieService,
    private _formb: FormBuilder,
    private _conn: ConnService
  ) { }

  ngOnInit(): void {
    // this.getCookie();
  }

  form:any;
  loginForm = this._formb.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });
  cookieValue:any;

  getCookie(){
    this.cookieValue = this._cookieService.get('sessions_key');
    console.warn('sessions_key: ' + this.cookieValue);
  }

  onSubmit(){
    let form = this.loginForm.value;
    //console.warn(form);
    this._conn.loging(form);
    // this.getCookie();
  }

}

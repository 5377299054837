<div class="container-fuild">
    <div class="containerx">

        <div class="row" style="margin-top: 20px;">


            <div class="col-12 col-sm-12 col-md-3 col-lg-3">

                <app-menu></app-menu>

            </div>

            <div class="col-12 col-sm-12 col-md-9 col-lg-9" style="min-height: 600px; border-left:1px solid #ccc;">

                <h2 style="border-bottom: 1px solid #ccc; padding-bottom: 15px;">
                    การตั้งค่าข้อมูลและภาพประจำตัว

                </h2>

                <form [formGroup]="instructorForm" (ngSubmit)="onSubmit()" >

                <div class="form-group" style="display: block;">
                    <label for="username">username</label>
                    <input
                        type="text"
                        class="form-control"
                        id="username"
                        placeholder="username"
                        formControlName="username">
                </div>
                <!-- end -->

                <div class="form-group">
                    <label for="Instructor">ชื่อสถาบัน/แบรนด์ดิ้ง</label>
                    <input
                        type="text"
                        class="form-control"
                        id="Instructor"
                        placeholder="ชื่อสถาบัน/แบรนด์ดิ้ง"
                        formControlName="instructor"
                        >
                </div>
                <!-- end -->
                <div class="form-group">
                    <label for="fullname">ชื่อ-สกุล</label>
                    <input
                        type="text"
                        class="form-control"
                        id="fullname"
                        placeholder="ชื่อสถาบัน/แบรนด์ดิ้ง"
                        formControlName="fullname"
                        >
                </div>
                <!-- end -->

                <div class="form-group">
                    <label for="course_tag">รูปแบบคอร์สเรียน</label>

                    <div style="margin-left: 50px;">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                        <label class="form-check-label" for="inlineCheckbox1">คอร์สสด/zoom</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                        <label class="form-check-label" for="inlineCheckbox2">Online</label>
                    </div>
                    </div>

                </div>
                <!-- end -->
                <div class="form-group">
                    <label for="Instructor">โปรไฟล์ย่อ</label>

                    <textarea
                        class="form-control"
                        formControlName="short_profiles"></textarea>
                </div>
                <!-- end -->
                <div class="form-group">
                    <label for="Instructor">ประวัติ Profile</label>

                    <editor
                        apiKey="9da7pov8xgbuoz7uswe96p1s7kx5j6pdwll6li57zmc2ir06"

                        formControlName="background_profiles"
                        [init]="{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'lists link image paste help wordcount code media hr fullscreen preview'
                        ],
                        toolbar:
                        'undo redo |
                        formatselect |
                        bold italic forecolor backcolor |
                        alignleft aligncenter alignright alignjustify |
                        bullist numlist outdent indent |
                        image link media hr |
                        code preview fullscreen'
                        }"
                      ></editor>

                </div>
                <!-- end -->
                <div class="row">

                    <div class="col-12">

                        <figure class="figure"  *ngFor="let img of image_asset" style="width: 180px;">
                            <img src="{{ img }}"
                                class="figure-img img-fluid rounded"
                                style="width: 150px;">
                            <figcaption class="figure-caption">

                            </figcaption>
                          </figure>
                        <hr>
                        <input
                            type="file"
                            name="profile_image"
                            (change)="getFileAsset($event.target.files)">
                        <!-- <button
                            (click)="fileUpload('profiles_assets')"
                            class="btn btn-primary">Upload profiles image</button> -->
                    </div>
                </div>

                <hr>
                <div class="row">

                    <div class="col-12">
                        <img
                            src="{{profiles_image}}"
                            class="img-fluid"
                            style="width: 150px;"><br>
                        <small>profiles image</small><br>
                        <input
                            type="file"
                            name="profile_image"
                            (change)="getFileProfile($event.target.files)">
                        <button
                            (click)="fileUpload('profiles')"
                            class="btn btn-primary">Upload profiles image</button>
                        <hr>
                    </div>


                    <div class="col-12">
                        <img
                            src="{{instructor_image}}"
                            class="img-fluid"
                            style="width: 100%;"><br>
                        <small>instructor image</small><br>
                        <input
                            type="file"
                            name="profile_image"
                            (change)="getFileInstructor($event.target.files)">

                    <button
                        (click)="fileUpload('instructor')"
                        class="btn btn-primary">Upload instructor image</button>
                    <hr>
                    </div>
                </div>

                <button type="submit" class="btn btn-primary">Update ข้อมูล</button>
                </form>
<!-- {{instructorForm.value | json}} -->
            </div>

        </div>
    </div>
</div>



import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';

// // For Production
// export const URLAPI:string = 'https://api.dekthai-online.com/dekthai/dekthai-api/';
export const URLAPI:string = 'https://api2.dekthai-online.com/';
export const ORIGIN: string = '.dekthai-online.com';

// For Localhost
// export const URLAPI:string = 'http://api.apu-school.local/';
// export const ORIGIN: string = '.apu-school.local';


export const HTTPOPTION:any = {
    headers: new HttpHeaders({'Content-Type':  'application/json' }),
    withCredentials: true
};


    <hr>


<div *ngFor="let cate of categorynew | keyvalue">

    <div class="row search-subject-zoom" >
      <div class="col">
          <p style="font-size: +1.4em;">{{cate.value['name']}}</p>
      </div>
  </div>

  <div class="row" style="background-color: #fff;">

    <div class="col-12 col-sm-6 col-md-6 col-lg-4" *ngFor="let c of cate.value['course']">

      <div class="browse_item">
        <a style="text-decoration: none;" routerLink="/course/{{c['ops_course_id']}}">
        <img class="browse_item_cover"
            src="{{c['course_image']}}"
            width="100%">
        </a>

        <div class="browse_item_body">

            <span
                style="font-size: 0.8em; font-weight: normal; margin-top: 20px; color:#3c832a;">
                    Online 100%
            </span>

            <p style="font-size: +1.3em;">
              <a style="text-decoration: none;" routerLink="/course/{{c['ops_course_id']}}">{{c['name']}}
              </a>
            </p>

            <small>{{c['info']}}</small>

            <!--
              <div class="browse_item_images_footer">

              <a *ngFor="let ins of c['instructor_id']" href="/instructor/{{ins[1]}}">
              <img
                  src="{{ins[3]}}"
                  alt="profiles"
                  data-toggle="tooltip" data-placement="top" title="{{ins[2]}}"
                  class="instructor_image" >
              </a>

            </div>
            -->

        </div>

        <div class="browse_item_footer">
            ราคา {{c['price'] | number:0}}<small>บาท</small>

          <span style="position: fixed; right: 10px;">
            <a routerLink="/course/{{c['ops_course_id']}}"  class="btn btn-primary btn-sm">ดูรายละเอียด</a>
          </span>
        </div>
      </div>        <!-- end browse_item -->


    </div>          <!-- end col -->

  </div>            <!-- end row -->

</div>              <!-- end category -->

<!-- <img class="img-thumbnail" src="https://assets.dekthai-online.com/00-728x90.jpg"> -->
<div class="container-fuild">
    <div>

        <div class="row" style="margin-top: 20px;">
            <div class="col-12 col-sm-12 col-md-3 col-lg-3">

                <app-menu></app-menu>

            </div>

            <div class="col-12 col-sm-12 col-md-9 col-lg-9" style="min-height: 600px; border-left:1px solid #ccc;">

                <h2 class="mat-h2" style="border-bottom: 1px solid #ccc; padding-bottom: 15px;">
                    แก้ไข id: {{blog['id']}}
                </h2>

                <div class="example-form">
                    <p>
                        <mat-form-field class="example-full-width">
                        <mat-label>Header Name</mat-label>
                        <input [(ngModel)]="blog['name']"
                            matInput >
                        </mat-form-field>
                    </p>

                    <p>
                        <mat-form-field class="example-full-width">
                        <mat-label>URL image title</mat-label>
                        <input [(ngModel)]="blog['img_title']"
                            matInput >
                        </mat-form-field>
                    </p>

                    <editor
                        style="margin-bottom: 5px;"
                        apiKey="9da7pov8xgbuoz7uswe96p1s7kx5j6pdwll6li57zmc2ir06"
                        [(ngModel)]="blog['content']"
                        [init]="{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'lists link image paste help wordcount code media hr fullscreen preview'
                        ],
                        toolbar:
                        'undo redo |
                        formatselect |
                        bold italic forecolor backcolor |
                        alignleft aligncenter alignright alignjustify |
                        bullist numlist outdent indent |
                        image link media hr |
                        code preview fullscreen'
                        }">
                    </editor>

                    <img *ngFor="let img of blog['images_assets']"
                        src="{{ img }}"
                        class="img-thumbnail"
                        style="height: 150px; margin-right: 5px;"><br>
                    <small>images assets</small><br>

                    <input
                        type="file"
                        name="profile_image"
                        (change)="getFileProfile($event.target.files)"
                        style="margin-bottom: 3px;">
                    <br>
                    <button

                        (click)="fileUpload('images_assets')"
                        class="btn btn-primary">Upload images assets</button>
                    <hr>

                    <button (click)="blogSave()" class="btn btn-primary" type="submit">บันทึก</button>

                </div>

            <!-- <small>
              {{ blog | json }}
            </small> -->

            </div>

        </div>
    </div>
</div>



import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IndexComponent } from './index/index.component';

const routes: Routes = [
  { path : 'classroom', component : IndexComponent },
//  {path : 'quizlists/:sid', component : QuizlistsComponent},
//  {path : 'quiz/:sid/:qid/:hash', component : QuizComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClassroomRoutingModule { }



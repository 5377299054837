<div class="container-fuild">
    <div>

        <div class="row" style="margin-top: 20px;">


            <div class="col-12 col-sm-12 col-md-3 col-lg-3">

                <app-menu></app-menu>

            </div>

            <div class="col-12 col-sm-12 col-md-9 col-lg-9" style="min-height: 600px; border-left:1px solid #ccc;">
                <h5 style="font-size: +1.2em; border-bottom: 1px solid #ccc; padding-bottom: 15px;">
                    Import Course From OPS
                </h5>


                <form [formGroup]="courseImport" (ngSubmit)="onImport()">
                    <div class="form-group row">
                      <label for="ops_course_id" class="col-sm-2 col-form-label">รหัสคอร์ส 4 หลัก</label>
                      <div class="col-sm-10">
                        <input
                            type="text"
                            class="form-control"
                            id="ops_course_id"
                            placeholder="ops_id"
                            formControlName="ops_course_id">
                      </div>
                    </div>
                    <div class="form-group row">
                        <label for="submit" class="col-sm-2 col-form-label"></label>
                        <div class="col-sm-10">
                          <button type="submit" class="btn btn-primary">Import</button><hr>
                          <span>{{course['name']}}</span>
                        </div>
                      </div>
                </form>

                <div class="row">
                    <div class="col-sm-2">

                    </div>
                    <div class="col-sm-10">
                        <button (click)="updatePrice()" class="btn btn-warning">อัพเดตราคาคอร์สทั้งหมด</button><br>
                        <small>(อัพเดตราคาคอร์สทั้งหมด ที่ id > 3000)</small> <hr>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>



import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IndexComponent } from './index/index.component';
import { MenuComponent } from './menu/menu.component';
import { TeacherComponent } from './teacher/teacher.component';
import { TeachereditComponent } from './teacheredit/teacheredit.component';

import { CourseComponent } from './course/course.component';
import { CourseimportComponent } from './courseimport/courseimport.component';
import { BlogComponent } from './blog/blog.component';
import { BlogeditComponent } from './blogedit/blogedit.component';
import { CourseeditComponent } from './courseedit/courseedit.component';
import { CategoryComponent } from './category/category.component';


const routes: Routes = [
  { path : 'admin', component : CourseComponent },
  { path : 'admin/teacher', component : TeacherComponent },
  { path : 'admin/teacher/:username', component : TeachereditComponent },
  { path : 'admin/course', component : CourseComponent },
  { path : 'admin/category', component : CategoryComponent },
  { path : 'admin/course/:cid', component : CourseeditComponent },
  { path : 'admin/courseimport', component : CourseimportComponent },
  { path : 'admin/blog', component : BlogComponent },
  { path : 'admin/blogedit/:bid', component : BlogeditComponent },

//  {path : 'quizlists/:sid', component : QuizlistsComponent},
//  {path : 'quiz/:sid/:qid/:hash', component : QuizComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }

import { Component, OnInit } from '@angular/core';

import { ConnService } from '../../conn.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styles: [
  ]
})
export class CarouselComponent implements OnInit {

  constructor(private _conn: ConnService) { }

  ngOnInit(): void {
    this.getBanner();
  }
  banner:any;
  async getBanner(){
    let banner = await this._conn.headerBanner();
    this.banner = banner;
    // console.warn(banner);
  }

}

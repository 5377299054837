<div class="container-fuild">
    <div >

        <div class="row" style="margin-top: 20px;">


            <div class="col-12 col-sm-12 col-md-3 col-lg-3">

                <app-menu></app-menu>

            </div>

            <div class="col-12 col-sm-12 col-md-9 col-lg-9" style="min-height: 600px; border-left:1px solid #ccc;">
                <h2 class="mat-h2" style="border-bottom: 1px solid #ccc; padding-bottom: 15px;">
                    แก้ไขรายละเอียดคอร์สเรียน
                </h2>


                <form class="form-inline" [formGroup]="cateForm" (ngSubmit)="getCourse()">

                    <div class="form-group mb-2">
                      <label for="staticEmail2" class="sr-only">Category</label>
                      <input type="text" readonly class="form-control-plaintext" id="staticEmail2" value="Category">
                    </div>

                    <div class="form-group mx-sm-3 mb-2">
                      <label for="inputPassword2" class="sr-only">Password</label>
                        <select
                            class="form-control"
                            id="exampleFormControlSelect1"
                            id="category"
                            formControlName="category">
                        >
                            <option value="0">
                                เลือก Category
                            </option>
                            <option
                                *ngFor="let cate of dekthaiCategory; let i = index;"
                                value="{{ cate }}">
                                {{ cate }}
                            </option>
                        </select>
                    </div>
                    <button type="submit" class="btn btn-primary mb-2">View Course</button>
                    <mat-spinner style="margin-left: 10px; padding-bottom: 10px;" *ngIf="loading" diameter="25" ></mat-spinner>
                </form>



                <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">course_id/site</th>
                        <th scope="col">course name</th>
                        <th scope="col">price</th>
                        <th scope="col">public</th>
                        <th scope="col">ผู้สอน</th>
                      </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let course of dekthaiCourse; let i = index;">
                            <td class="text-center">
                                <a routerLink="/admin/course/{{course['ops_course_id']}}">{{ course['ops_id'] }} | {{ course['ops_course_id'] }}</a><br>
                                <span style="color: brown;">{{ course['site'] }}</span>
                            </td>
                            <td>{{ course['name'] }}</td>
                            <td>
                                {{ course['price'] | number:0}}
                            </td>

                            <td>
                                <mat-slide-toggle (change)="course_public(i, $event)"  [(ngModel)]="course['status']"></mat-slide-toggle>
                            </td>

                            <td>{{ course['teacher'] }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


    </div>
</div>



<div class="container-fuild">
    <div class="containerx">

        <div class="row" style="margin-top: 20px;">


            <div class="col-12 col-sm-12 col-md-3 col-lg-3">

                <app-menu></app-menu>

            </div>

            <div class="col-12 col-sm-12 col-md-9 col-lg-9" style="min-height: 600px; border-left:1px solid #ccc;">

                <h2 style="border-bottom: 1px solid #ccc; padding-bottom: 15px;">
                    การตั้งค่าข้อมูลและภาพประจำตัว &emsp;&nbsp;&emsp;
                    <!-- <div class=" form-check-inline">
                        <select class="form-control" (change)="selectInstructor($event.target.value)">
                            <option *ngFor="let ins of instructorlist" value="{{ins.username}}">{{ins.fullname}}</option>
                        </select>
                    </div> -->
                </h2>

                <table class="table">
                    <thead class="thead-dark">
                    <tr>
                        <th class="text-center" width="75%">Teacher</th>
                        <th >Edit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let ins of instructorlist">
                        <td>
                            {{ ins.fullname }}<br>
                            <small>{{ ins.instructor }}</small>
                        </td>
                        <td>
                            <a routerLink="/admin/teacher/{{ ins.username }}">{{ ins.username }}</a>
                        </td>
                    </tr>
                </tbody>

                </table>


            </div>

        </div>
    </div>
</div>



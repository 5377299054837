import { Component, OnInit } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { ConnService } from '../../conn.service';
import { AdminService } from '../../admin.service';

import { URLAPI, HTTPOPTION } from '../../app.conn';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./../index/index.component.css']
})
export class CourseComponent implements OnInit {

  constructor(
    private _formb: FormBuilder,
    private _http: HttpClient,
    private _conn: ConnService,
    private _admin: AdminService
  ) { }

  URLAPI:string;
  authorizationData;
  dekthaiCourse:any = [];
  dekthaiCategory:any = [];
  HTTPOPTION;

  loading = false;

  ngOnInit(): void {
    this.URLAPI = URLAPI;
    this.HTTPOPTION = HTTPOPTION;

    this.authorizationData = this._conn.authorizationData();
    // this.getDekthaiCourse();

    this.loading = true;
    this.getDekthaiCategory();

  }

  async getDekthaiCategory(){
    let dekthaiCategory:any[] = await this._admin.dekthaiGetCategory();
    this.dekthaiCategory = dekthaiCategory;
    console.warn(dekthaiCategory);
    this.loading = false;
  }

  cateForm = this._formb.group({
    category: ['0', Validators.required]
  });


  getCourse(){
    this.loading = true;
    this.dekthaiCourse = [];

    let formData = this.cateForm.value;
    let category = formData['category'];
    // console.warn(category);

    this.getDekthaiCourse( formData );
  }


  async getDekthaiCourse(formData){
    console.warn(formData);

    let dekthaiCourse = await this._admin.dekthaiCourse( formData );
    this.dekthaiCourse = dekthaiCourse['data'];
    this.loading = false;

  }

  async course_public(index, e){
    // console.log(this.dekthaiCourse[index]);


    let api = 'a2/updateCourse';
    let url = this.URLAPI + api;

    let formData = this.dekthaiCourse[index];
    console.warn(formData);

    let response = await this._http
      .post(url, formData, this.HTTPOPTION )
      .pipe()
      .toPromise();

    console.warn(response);

  }

}

<div class="container" style="padding: 50px; max-width: 550px;">





    <div class="row">
        <div class="col"
            style="
                box-shadow: 0 0 1px rgb(90 90 90);
                border-radius: 8px;
                padding-top: 40px;
                padding-bottom: 40px;">
            <h3>Login Form</h3>

            <form
                [formGroup]="loginForm"
                (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="name"><small>User id</small></label>
                    <input
                        type="text"
                        class="form-control"
                        id="user"
                        placeholder="User id"
                        formControlName="username">
                </div>

                <div class="form-group">
                    <label for="name"><small>Password</small></label>
                    <input
                        type="password"
                        class="form-control"
                        id="password"
                        placeholder="Password"
                        formControlName="password">
                </div>

                <div class="form-group text-center">
                    <button
                        type="submit"
                        class="btn btn-primary text-center"
                        style="width: 60%;"
                        [disabled]="!loginForm.valid"
                        >
                        Login
                    </button>
                </div>
            </form>

        </div>

    </div>

</div>
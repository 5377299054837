<div class="row search_checkbox">
    <div class="col-12 col-sm-4 col-md-4 col-lg-4" style="margin-top: 10px;">

      <strong>รูปแบบการเรียน</strong>
      <form>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox11" value="option11" checked>
          <label class="form-check-label" for="inlineCheckbox11">สด/Zoom</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox12" value="option12"  checked>
          <label class="form-check-label" for="inlineCheckbox12">Online 100%</label>
        </div>
      </form>
    </div>
    <div class="col-12 col-sm-8 col-md-8 col-lg-8" style="margin-top: 10px;">
      <strong>เลือกวิชา</strong>
      <form>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" checked>
            <label class="form-check-label" for="inlineCheckbox1">คณิตศาสตร์</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" checked>
            <label class="form-check-label" for="inlineCheckbox2">วิทยาศาสตร์</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3">
            <label class="form-check-label" for="inlineCheckbox3">ภาษาอังกฤษ</label>
          </div>

      </form>
    </div>
</div>
<hr>
<h6  style="text-align: right; margin-top: 25px;">คะแนน TEST/การบ้าน</h6>

<div id="div_chart_test" style="height: 300px; width: 100%;" ></div>


<div class="row"
            style="text-align: center;
            background-color: #eee;
            border-radius: 10px;
            margin: 0px;
            margin-top: 15px;
            margin-bottom: 15px;"
>
    <div class="col-12 col-sm-12 col-md-4 col-lg-4 text-center"
        style="margin-top: 15px; margin-bottom: 15px;">คะแนนเฉลี่ยนักเรียน:  <span class="text-success">78.21%</span></div>
    <div class="col-12 col-sm-12 col-md-4 col-lg-4 text-center"
        style="margin-top: 15px; margin-bottom: 15px;">คะแนนเฉลี่ยห้อง:  49.58%</div>
    <div class="col-12 col-sm-12 col-md-4 col-lg-4 text-center"
        style="margin-top: 15px; margin-bottom: 15px;">ขาดสอบ:  14 ครั้ง</div>
</div>

<table class="table table-striped">
    <thead>
    <tr class="table-info">
        <th class="text-center info">เรื่องที่สอบ</th>
        <th class="text-center info">วันที่สอบ</th>
        <th class="text-center info">คะแนน/<span class="text-secondary">retest</span></th>
        <th class="text-center info">เฉลี่ยห้อง</th>
        <th class="text-center info"><i class="fas fa-paste"></i></th>
    </tr>
    </thead>

    <tbody>
        <tr *ngFor="let t of test_data_table">
            <td>{{ t['section']['name']}}</td>
            <td class="text-center">{{ t['dateTh'] }}</td>
            <td class="text-center">
                <span class="text-success">{{ t['percent'] }}%</span>
                &nbsp;<span class="text-secondary" *ngIf="t['retest_percent'] > 0"><small>({{ t['retest_percent'] }}%)</small></span>
            </td>
            <td class="text-center">{{ t['avg_percent'] }}</td>
            <td class="text-center">-</td>
        </tr>
    </tbody>

</table>
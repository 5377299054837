<h5 style="text-align: right; margin-top: 45px;">ประวัติการเรียน</h5>
<hr>
<h6  style="text-align: right;">ประวัติ Quiz</h6>

<table class="table table-striped">
    <thead>
    <tr class="table-info">
        <th class="text-center info">วันที่</th>
        <th class="text-center info">รายละเอียด</th>
        <th class="text-center info">T-score</th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let q of lastQuiz">
        <td class="text-center">{{ q['quiz_date'] }}</td>
        <td>{{ q['name'] }}</td>
        <td class="text-center">{{ q['tscore'] }}</td>
    </tr>
    </tbody>
</table>

<h5 style="text-align: right; margin-top: 45px;">ประวัติการทำแบบทดสอบ</h5>
<hr>

<h5 style="text-align: right; margin-top: 45px;">ประวัติการ Retest</h5>
<hr>

<h5 style="text-align: right; margin-top: 45px;">ประวัติการเรียน</h5>
<hr>
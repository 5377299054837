import { Component, OnInit } from '@angular/core';

import { Pipe, PipeTransform } from '@angular/core';

import { quiz_data_table, quiz_data_graph } from '../../../assets/quiz_data';

declare var google;
declare var div_chart_quiz;

@Component({
  selector: 'app-quizscore',
  templateUrl: './quizscore.component.html',
  styles: [
  ]
})
export class QuizscoreComponent implements OnInit {

  constructor() { }
  quiz_data_table:any;

  ngOnInit(): void {
    google.charts.load('current', {'packages':['corechart']});
    google.charts.setOnLoadCallback(this.drawChart);

    console.log(quiz_data_table);
    console.log(quiz_data_graph);
    this.quiz_data_table = quiz_data_table;
  }


  drawChart() {
    console.log('drawchart');
    let dataG = quiz_data_graph;
    //console.log( dataG);
      var stDetail = new google.visualization.DataTable(dataG);
      var options = {
        chartType:"ComboChart",
        title: 'คะแนน Quiz (T-score) ของนักเรียนเส้นเขียวทึบ',
        legend: { position: 'top'},
        hAxis: {
          title: '',
          minValue: 0,
          slantedTextAngle: 45
        },
        vAxis: {
          title: 't-score',
          minValue: 0,
          viewWindow: {
            min: 15,
            max: 90
          }
        },
        chartArea: {backgroundColor: '#c1f4c7'},
        //bar: {groupWidth: "20%"},
        seriesType: 'bars',
        series: {
          //0: {color: '#1ec331'},
          3: {type: 'line', 'color': '#0d8748'}, // me
          0: {type: 'line', 'color': '#ff909a', lineDashStyle: [10, 2]},
          1: {type: 'line', 'color': '#376ef9', lineDashStyle: [10, 2]},
          2: {type: 'area', 'color': '#70d7ed', areaOpacity: 0.8, lineDashStyle: [10, 2]}
        },
        pointSize: 3,
        pointShape: 'circle',
        tooltip: { trigger: 'selection' },
        annotations: {
          stem: {
            color: 'transparent'
          },
          style: 'line',
          textStyle: {fontSize: 12, color: '#1D017C', opacity: 0.8 }
        }
      };
      //var charTestNo = new google.visualization.LineChart(div_chart_quiz);
      //var charTestNo = new google.visualization.BarChart(div_chart_quiz);
      var charTestNo = new google.visualization.ColumnChart(div_chart_quiz);
      charTestNo.draw(stDetail, options);

  }

}

<div class="container" style="padding-top: 25px;">

        <div class="row" style="margin-top: 25px;">

            <div class="col-12 col-sm-12 col-md-3 col-lg-3">

                <ul class="nav flex-column">
                    <li class="nav-item">
                      <a class="btn btn-light active btntab">Chapter 846 ...</a>
                    </li>
                    <li class="nav-item">
                      <a class="btn btn-light btntab" >Chapter 942 ...</a>
                    </li>

                  </ul>

            </div>

            <div class="col-12 col-sm-12 col-md-9 col-lg-9">

                <h5 style="text-align: right;">U334 คณิตพี่ปุ๊ตะลุยโจทย์คณิตเข้าเตรียมอุดม</h5>
                <hr>

                <app-quizscore></app-quizscore>

                <app-testscore></app-testscore>

            </div>

        </div>
</div>




import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConnService } from '../../conn.service';
import { URLAPI, HTTPOPTION } from '../../app.conn';

@Component({
  selector: 'app-mycourse',
  templateUrl: './mycourse.component.html',
  styleUrls: ['./../index/index.component.css']
})
export class MycourseComponent implements OnInit {

  constructor(
    private _conn: ConnService,
    private _http: HttpClient
  ) {  }

  authorizationData;
  user;
  URLAPI;
  courseLists;

  ngOnInit(): void {
    this.authorizationData = this._conn.authorizationData();
    this.user = this._conn.getCookie();
    this.URLAPI = URLAPI;
    this.getMycourse();
  }

  async getMycourse(){
    console.warn('getMycourse');
    console.warn(this.authorizationData);
    console.warn(this.user);

    let httpOptions = {
      headers: new HttpHeaders({ 'content-type': 'application/json', 'authorization': this.authorizationData })
    };

    let api = 'st';
    let url = this.URLAPI + api;
    let response = await this._http
      .get(url, httpOptions )
      .pipe()
      .toPromise();

    this.courseLists = response['courseLists'];
    console.warn(this.courseLists);
  }

}

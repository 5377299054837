import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';

import { URLAPI, HTTPOPTION } from '../../app.conn';

@Component({
  selector: 'app-instructor',
  templateUrl: './instructor.component.html',
  styleUrls: ['./instructor.component.css']
})
export class InstructorComponent implements OnInit {

  constructor(
          private _http: HttpClient,
          private _route: ActivatedRoute,
          private _title: Title,
          private _meta: Meta
        ) { }

  URLAPI:string;
  HTTPOPTION:any;

  username:string;
  instructor:any;

  ngOnInit(): void {
    //console.log(instructor);
    this.URLAPI = URLAPI;
    this.HTTPOPTION = HTTPOPTION;
    //this.instructor = instructor;
    let sub = this._route.params.subscribe(params => {
      this.username = params['username'];
    });
    this.getInstructor();
    window.scroll(0,0);
  }

  setTittle(){
    // console.warn(this.instructor['instructor_image']);
    // Line ==> name
    // Facebool ==> property

    this._title.setTitle('แนะนำ '+this.instructor['instructor']);
    this._meta.addTags([
      { name: 'og:type', content: 'article' },
      { name: 'og:url', content: 'dekthai-online.com' },
      { name: 'og:title', content: 'แนะนำ '+this.instructor['instructor'] },
      { name: 'og:description', content: this.instructor['short_profiles'] },
      { name: 'og:image', content: this.instructor['profiles_image'] },
      { property: 'og:type', content: 'article' },
      { property: 'og:url', content: 'dekthai-online.com' },
      { property: 'og:title', content: 'แนะนำ '+this.instructor['instructor'] },
      { property: 'og:description', content: this.instructor['short_profiles'] },
      { property: 'og:image', content: this.instructor['instructor_image'] }
    ]);

    // <meta property="og:title" content="คอร์สเรียน สด Zoom online อ.ปุ๊">
		// <meta property="og:description" content="คลิกเพื่อดูรายละเอียดคอร์สได้ทันที">
		// <meta property="og:image" content="https://www.apu-school.com/static/20200618-091244.jpg">
  }

  showHall:any;
  async getInstructor(){
    // console.warn('instructor: '+this.username);

    let api = 'g/Instructor'
    let params = '/'+this.username;
    let url = this.URLAPI+api+params;
    let showHall = false;

    if( this.username === 'apu-school'){
      showHall = true;
    }

    this.showHall = showHall;

    let response = await this._http
      .get(url, this.HTTPOPTION)
      .pipe()
      .toPromise();
      console.log(response);

      this.instructor = response['instructor'];
      this.setTittle();

  }

}

<div class="container-fuild">
    <div class="container">
        <div class="row" style="margin-top: 15px;">
            <div class="col-12">
                <h2>ลงทะเบียนนักเรียนใหม่</h2><hr>
                <h5><u>ขั้นตอนการลงทะเบียนนักเรียนใหม่</u></h5>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3 text-center" style="margin-top: 10px;">
                <strong><u>ขั้นตอนที่ 1</u></strong><br><br>
                เพิ่มเพื่อนใน Line <a href="https://www.apu-school.com/t/?line" target="_blank">@apu-school</a>
                <br>
                <img style="margin-top: 10px;"  src="https://assets.dekthai-online.com/static/profile_32eab879be457fae.png" class="img-thumbnail">
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-3 text-center" style="margin-top: 10px;">
                <strong><u>ขั้นตอนที่ 2</u></strong><br><br>
                คลิกเข้าสู่ Quiz<br>
                <img style="margin-top: 10px;"  src="https://assets.dekthai-online.com/static/profile_a67d82979ea7e97d.jpg" class="img-thumbnail">
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-3 text-center" style="margin-top: 10px;">
                <strong><u>ขั้นตอนที่ 3</u></strong><br><br>
                คลิก ลงทะเบียนนักเรียนใหม่<br>
                <img style="margin-top: 10px;"  src="https://assets.dekthai-online.com/static/profile_c9b4025711ee9d52.jpg" class="img-thumbnail">
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-3 text-center" style="margin-top: 10px;">
                <strong><u>ขั้นตอนที่ 4</u></strong><br><br>
                กรอกข้อมูลให้ครบถ้วน<br>
                <img style="margin-top: 10px;" src="https://assets.dekthai-online.com/static/profile_3a0b412233f8d9e6.jpg" class="img-thumbnail">

            </div>

        </div>
</div>
<div class="footer-dark">
    <footer>
        <div class="container">
            <div class="row">

                <div class="col-sm-6 col-md-3 item">
                    <h3>ค้นหาคอร์สเรียน</h3>
                    <ul>
                        <li><a routerLink="/instructor/apu-school" href="/instructor/apu-school">คณิตศาสตร์</a></li>
                        <!-- <li><a href="#">วิทยาศาสตร์</a></li>
                        <li><a href="#">ภาษาอังกฤษ</a></li> -->
                    </ul>
                </div>

                <div class="col-sm-6 col-md-3 item">
                    <h3>DekThai Online</h3>
                    <ul>
                        <li><a routerLink="/contact" href="/contact">ติดต่อ</a></li>
                        <!-- <li><a href="/blog">บทความ</a></li> -->
                        <!-- <li><a href="#">ร่วมงานสอน</a></li> -->
                        <!-- <li><a href="#">แจ้งปัญหาเกี่ยวกับระบบ</a></li> -->
                    </ul>
                </div>

                <div class="col-md-6 item text">
                    <h3>DekThai Online</h3>
                    <p>ศูนย์รวมติวเตอร์คุณภาพเพื่อเด็กไทย</p>
                </div>

                <div class="col item social">
                    <a href="https://www.apu-school.com/t/?fbdekthai" target="_blank"><i class="fab fa-facebook"></i></a>
                    <!-- <a target="_blank"><i class="fab fa-youtube"></i></a> -->
                    <a href="https://www.apu-school.com/t/?dekthai"  target="_blank"><i class="fas fa-comments"></i></a>
                </div>
            </div>
            <p class="copyright">APU EDUCATION Co.,Ltd.</p>
        </div>
    </footer>
</div>
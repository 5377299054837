import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { URLAPI, HTTPOPTION } from '../../app.conn';

@Component({
  selector: 'app-recommend',
  templateUrl: './recommend.component.html',
  styles: [
  ]
})
export class RecommendComponent implements OnInit {

  constructor(
    private _http: HttpClient,
    private _route: ActivatedRoute
  ) { }

  URLAPI:string;
  HTTPOPTION:any;

  ops_course_id:string;
  crelation:any;


  ngOnInit(): void {
    console.log('recommend');
    this.URLAPI = URLAPI;
    this.HTTPOPTION = HTTPOPTION;

    let sub = this._route.params.subscribe(params => {
      this.ops_course_id = params['ops_course_id'];
    });
    console.warn(this.ops_course_id);
    //this.getRecommend();
  }

  async getRecommend(){
    console.warn('ops_course_id: '+this.ops_course_id);

    let api = 'G/CourseRelation'
    let params = '/opscourseid/'+this.ops_course_id;
    let url = this.URLAPI+api+params;

    let response = await this._http
      .get(url, this.HTTPOPTION)
      .pipe()
      .toPromise();
      console.log(response);

      if(response['error'] == 0){
        let data  = response['data'];
        this.crelation     = data['course_relation'];
      }



  }

}

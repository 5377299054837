  <!-- <div class="row search-subject-zoom">
    <div class="col">
      <h4>สด/zoom</h4>
    </div>
  </div> -->

  <div class="row" style="background-color: #fff;">

    <div class="col-12 col-sm-6 col-md-4 col-lg-3" *ngFor="let ins of instructor">
      <div class="browse_item">
        <!-- img header -->
        <a routerLink="/instructor/{{ ins['username'] }}" *ngIf="ins['course_active'] != 0">
        <img
            src="{{ ins['instructor_image'] }}"
            width="100%">
        </a>
        <img *ngIf="ins['course_active'] == 0"
            src="{{ ins['instructor_image'] }}"
            width="100%">

        <div class="browse_item_body">

            <span class="browse_course_tag">
                {{ins['course_tag']}}
            </span>

            <p style="font-size: 1.2em;">

                <a routerLink="/instructor/{{ ins['username'] }}"
                  *ngIf="ins['course_active'] != 0"
                  style="color: #000;
                  text-decoration: none;">
                  {{ins['instructor']}}
                </a>
                <span *ngIf="ins['course_active'] == 0">{{ins['instructor']}}</span>

            </p>

            <small>
                {{ins['short_profiles']}}
            </small>

        </div>

        <div class="browse_item_footer">
          <i class="fas fa-list"></i> {{ ins['course_active'] }} คอร์ส
          <span style="position: fixed; right: 10px;">
            <a
              *ngIf="ins['course_active'] != 0"
              routerLink="/instructor/{{ ins['username'] }}"
              class="btn btn-primary btn-sm">ดูคอร์ส</a>
          </span>
        </div>
      </div>        <!-- end browse_item -->
    </div>          <!-- end col -->


  </div>
<ul class="nav flex-column">

    <li class="nav-item">
        <a routerLink="/admin/teacher"  class="btn btn-light btntab">ติวเตอร์/สถาบัน</a>
        <a routerLink="/admin/courseimport"  class="btn btn-light btntab">ImportCourse</a>
        <a routerLink="/admin/course"  class="btn btn-light btntab">แก้ไขรายละเอียดคอร์ส</a>
        <a routerLink="/admin/category"  class="btn btn-light btntab">เพิ่ม Category Course</a>
        <a routerLink="/admin/blog"  class="btn btn-light btntab">เพิ่มบทความ</a>
        <a routerLink="/admin/site"  class="btn btn-light btntab">แก้ไข Slide, footer</a>
    </li>

  </ul>

import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { ConnService } from './../conn.service';


@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(
    private _cookieService: CookieService,
    private _conn: ConnService
  ) {

    this.logoutAction();
  }

  ngOnInit(): void {
    //this._conn.getUser();
    //localStorage.setItem('dataSource', this.dataSource.length);
    //this.getCookie();
    //this._conn.is_cookie();
    //this._conn.get();
    // this._conn.logout();
  }

  async logoutAction(){
    await this._conn.logout();
  }





}

<h6  style="text-align: right;">คะแนน Quiz</h6>

<div class="row">
    <div
        class="col-12 col-sm-12 col-md-3 col-lg-3 text-center"
        style="padding-top: 25px;">
        <img width="80%" src="https://www.apu-school.com/online/static/images/meter_good.jpg">
        <p>T-score รวม 58.95</p>
    </div>
    <div class="col-12 col-sm-12 col-md-9 col-lg-9">
        <div id="div_chart_quiz" style="height: 300px; width: 100%;" ></div>
    </div>
</div>


<table class="table table-striped">
    <thead>
    <tr class="table-info">
        <th class="text-center info">วันที่</th>
        <th class="text-center info">รายละเอียด</th>
        <th class="text-center info">T-score</th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let q of quiz_data_table">
        <td class="text-center">{{ q['dateTh'] }}</td>
        <td>{{ q['name'] }}</td>
        <td class="text-center">{{ q['tscore'] }}</td>
    </tr>
    </tbody>
</table>
<hr>
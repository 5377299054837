import { Inject, Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

//import { BrowserXhr } from '@angular/http';

import { URLAPI, HTTPOPTION, ORIGIN } from './app.conn';


@Injectable({
  providedIn: 'root'
})
export class ConnService {

  constructor(
    @Inject(LOCAL_STORAGE) private _storage: StorageService,
    private _cookieService: CookieService,
    private _http: HttpClient,
    private _router: Router,
  ) { }

  URLAPI:string;
  HTTPOPTION:any;

  authorizationData(){
    //const is_sessions_key: boolean =  this._cookieService.check('sessions_key');
    const is_sessions_key: boolean =  this._cookieService.check('sessions_key');
    let authorization_data:string;

    // console.log('sessions_key : '+this._cookieService.get('sessions_key'))
    if(is_sessions_key){
      let sessions_key = this._cookieService.get('sessions_key');
      let sessions_data = this._cookieService.get('sessions_data');
      authorization_data = 'sessions_key='+this._cookieService.get('sessions_key')+'; sessions_data='+sessions_data;
    }else{
      authorization_data = 'sessions_key=; sessions_data=';
    }
    return authorization_data;
  }

  async get(){
    // console.log('get: G/get');
    let api = 'a';
    let url = URLAPI + api;
    let authorization = this.authorizationData();
    // console.log('authorization:' + authorization);

    let httpOptions = {
      headers: new HttpHeaders({ 'content-type': 'application/json', 'authorization': authorization}),
      withCredentials: true,
    };

    let response = await this._http
      .get(url, httpOptions )
      .pipe()
      .toPromise();
      //console.warn(response['instructor']);

      let user = response['user'];
      let instructorlist = response['instructor'];

      if(user['login']){
        this.createCookie( user );
        this._storage.set('user', user);
      }else{
        //this.logout();
        // alert('clearCookie');
        // console.warn('clearcookie');

        // window.open("/login", "_self");
        this._router.navigateByUrl('/login');
        this.clearCookie();

      }
      this._storage.set('instructorlist', instructorlist);

  }

  async loging(form){
    console.warn('_conn login');

    let api = 'a/login';
    let url = URLAPI + api;

    let httpOptions = {
      headers: new HttpHeaders({ 'content-type': 'application/json' })
    };

    //{ withCredentials: true, credentials: 'include' }
    let response = await this._http
      .post(url, form, httpOptions )
      .pipe()
      .toPromise();

      let data  = response['data'];
      let user  = response['user'];
      //let is_cookie = data['is_cookie'];
      //console.warn(response);

      if(user['login']){
        console.warn('login complete');
        // console.warn(user);

        this._cookieService.set( 'sessions_data', user['sessions_data'], {path: '/', expires: 3, sameSite: 'Strict', domain: ORIGIN} );
        this._cookieService.set( 'sessions_key', user['sessions_key'], {path: '/', expires: 3, sameSite: 'Strict', domain: ORIGIN} );

        this.createCookie(user);

        if(user['is_staff'] == 1){
          window.open("/admin", "_self");
          // this._router.navigateByUrl('/admin');
        }else{
          window.open("/teacher", "_self");
          // this._router.navigateByUrl('/teacher');
        }


      }else{
        alert('Wrong Password!');
      }
  }

  logout(){
    this.clearCookie();
    //this._router.navigateByUrl('/');
    this.get();

    window.open("/home", "_self");
  }

  getCookie(){
    // console.log('getCookie');
    let user = [];
      user['user'] = this._cookieService.get('user');
      user['fullname'] = this._cookieService.get('fullname');
      user['userid'] = Number(this._cookieService.get('userid'));
      user['is_staff'] = Number(this._cookieService.get('is_staff'));
      user['instructor_id'] =Number(this._cookieService.get('instructor_id'));
      user['ops_teacher_name'] =this._cookieService.get('ops_teacher_name');
    // console.log(user);
    return user;
  }

  clearCookie(){
    console.log('clearCookie');
    const allCookies: {} = this._cookieService.getAll();
    console.warn( allCookies );

    this._cookieService.deleteAll( '/', ORIGIN, false, 'Strict');

    // this._cookieService.delete('sessions_data');
    // this._cookieService.delete('sessions_key');
    // this._cookieService.delete('user');
    // this._cookieService.delete('userid');
    // this._cookieService.delete('is_staff');
    // this._cookieService.delete('instructor_id');
    // this._cookieService.delete('fullname');

    // this._cookieService.set( 'sessions_data', '', { expires: 3 });
    // this._cookieService.set( 'sessions_key', '', { expires: 3 });
    // this._cookieService.set( 'user', '' );
    // this._cookieService.set( 'fullname', '' );
    // this._cookieService.set( 'userid', '' );
    // this._cookieService.set( 'is_staff', '' );
    // this._cookieService.set( 'instructor_id', '' );

  }

  createCookie( user ){
    // console.log('function createCookie: ');
    // console.warn(user);
    // this._cookieService.set( 'login', user['user'], {path: '/', expires: 3, sameSite: 'Strict', domain: ORIGIN});
    this._cookieService.set( 'user', user['user'], {path: '/', expires: 3, sameSite: 'Strict', domain: ORIGIN});
    this._cookieService.set( 'fullname', user['fullname'], {path: '/', expires: 3, sameSite: 'Strict', domain: ORIGIN});
    this._cookieService.set( 'userid', user['user_id'], {path: '/', expires: 3, sameSite: 'Strict', domain: ORIGIN});
    this._cookieService.set( 'is_staff', user['is_staff'], {path: '/', expires: 3, sameSite: 'Strict', domain: ORIGIN});
    this._cookieService.set( 'instructor_id', user['instructor_id'], {path: '/', expires: 3, sameSite: 'Strict', domain: ORIGIN});
    this._cookieService.set( 'ops_teacher_name', user['ops_teacher_name'], {path: '/', expires: 3, sameSite: 'Strict', domain: ORIGIN});
  }

  async headerBanner(){
    // console.warn('headerBanner');
    let api = 'g/banner';
    let url = URLAPI + api;
    let response = await this._http
      .get(url, this.HTTPOPTION )
      .pipe()
      .toPromise();

    // console.warn(response['banner']);
    return response['banner'];
  }

  simpleUser(){
      let user = this.getCookie();
      return user;
  }

}

<div class="row search_textbox"
    style="
        background: url(https://assets.dekthai-online.com/i.php?/upload/2021/02/13/20210213034112-b1d9f02e-xl.png);
        background-repeat: repeat;
        // background-attachment: fixed;
        background-position: center;"
>
    <div style="display: none; text-align: center;" class="search-course col text-center mx-auto text-center justify-content-center">
        <h3>ค้นหาคอร์สเรียน</h3>
            <form style="display: inline-block; width: 50%;" class="text-center">
            <input style="width: 100%; text-align: center; align-items: center;"
                class="form-control form-control-lg"
                type="text" placeholder="ค้นด้วยชื่อคอร์ส วิชา ระดับชั้น หรือ ครูผู้สอน">
            </form>
    </div>
</div>

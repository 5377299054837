import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';

import { AppComponent } from './app.component';

// import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { RegisterComponent } from './site/register/register.component';
import { BankComponent } from './site/bank/bank.component';


const routes: Routes = [
  //{path: 'home', component: HomeComponent},
  {path: 'login', component: LoginComponent},
  {path: 'logout', component: LogoutComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'bank', component: BankComponent},
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  // {path: 'browse', component: IndexComponent},
  // {path: '', component: HomeComponent},
  // {path: '**', redirectTo: 'browse', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router) { }

  ngOnInit() {
      this.router.events.subscribe((evt) => {
          if (!(evt instanceof onanimationend)) {
              return;
          }
          //window.scrollTo(0, 0)
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';

import { URLAPI, HTTPOPTION } from '../../app.conn';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {

  constructor(
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _title: Title,
    private _meta: Meta,
    private _sanitizer: DomSanitizer
  ) { }

  URLAPI:string;
  HTTPOPTION:any;

  ops_course_id;
  course;
  category:any;

  content:any;

  ngOnInit(): void {
    //console.log(instructor);
    this.URLAPI = URLAPI;
    this.HTTPOPTION = HTTPOPTION;

    let sub = this._route.params.subscribe(params => {
      this.ops_course_id = params['ops_course_id'];
    });
    //console.warn(this.ops_course_id);
    this.getCourse();
    window.scroll(0,0);

    // let html = '<div><iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL0cCvSmf3Rcg-U0T66xeGUKjU9Er4H9a3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>';
    // this.content= this.getHTML(html);


  }

  getHTML(html){
    let str = this._sanitizer.bypassSecurityTrustHtml(html);
    return str;
  }

  showHall:any;
  async getCourse(){
    // console.warn('ops_course_id: '+this.ops_course_id);

    let api = 'g/course'
    let params = '/' + this.ops_course_id;
    let url = this.URLAPI+api+params;

    let response = await this._http
      .get(url, this.HTTPOPTION)
      .pipe()
      .toPromise();
      console.warn(response);

      let course = response['course'];
      this.course = course;

      // alert( course);
      if(course == null){
        window.open("/browse", "_self");
        //alert('null');
      }
      this.content = this.getHTML(course['content']);

      let showHall = false;

      if( course['instructor']['username'] === 'apu-school'){
        showHall = true;
      }

      if(this.course != ''){
        this.setTittle()
      }else{
        //alert(404);
      }
  }

  setTittle(){
    console.warn('setTittle');
    this._title.setTitle('แนะนำ '+this.course['name']);
    this._meta.addTags([
      { name: 'og:type', content: 'article' },
      { name: 'og:url', content: 'dekthai-online.com' },
      { name: 'og:title', content: 'แนะนำ '+this.course['name'] },
      { name: 'og:description', content: this.course['info'] },
      { name: 'og:image', content: this.course['course_image'] },
      { property: 'og:type', content: 'article' },
      { property: 'og:url', content: 'dekthai-online.com' },
      { property: 'og:title', content: 'แนะนำ '+this.course['name'] },
      { property: 'og:description', content: this.course['info'] },
      { property: 'og:image', content: this.course['course_image'] }
    ]);

    // <meta property="og:title" content="คอร์สเรียน สด Zoom online อ.ปุ๊">
		// <meta property="og:description" content="คลิกเพื่อดูรายละเอียดคอร์สได้ทันที">
		// <meta property="og:image" content="https://www.apu-school.com/static/20200618-091244.jpg">
  }

  scrollTo(){
    // alert('scrollTo');
    console.log('scrollTo');
    var elmnt = document.getElementById("bank");
    elmnt.scrollIntoView();
  }



}

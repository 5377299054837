<div class="container-fuild">
    <div class="container">
        <div class="row" style="margin-top: 15px;">
            <div class="col-12">
                <h2>Contact Us</h2>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6" style="margin-top: 10px; display: block;">
                <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3827.151318240026!2d102.83017511442335!3d16.41713958866672!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3122898a410f107b%3A0x3e9234576d14e508!2z4LmC4Lij4LiH4LmA4Lij4Li14Lii4LiZ4LiB4Lin4LiU4Lin4Li04LiK4Liy4Lit4LiH4Lit4Liy4LiI4Lig4Liy4Lij4LiU4Li1ICjguK0u4Lib4Li44LmKKQ!5e0!3m2!1sth!2sth!4v1484361691791" width="100%" height="450" frameborder="0" style="border:0" allowfullscreen=""></iframe> -->
                <img class="img-fluid" src="https://assets.dekthai-online.com/upload/2021/04/23/20210423104948-3b74b7ca.jpg" >
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6" style="margin-top: 10px;">

                บริษัท บริษัท เอพียู เอ็ดดูเคชั่น จำกัด <hr>
                <!-- 728/34 ถนนหน้าเมือง ตำบลในเมือง อำเภอเมือง จังหวัดขอนแก่น 40000<hr> -->
                <!-- เบอร์สำนักงาน : 043-007-229, 087-643-4343<hr> -->

                <!-- <a href="https://www.apu-school.com/t/?fbdekthai" target="_blank"><i class="fab fa-facebook"></i>&nbsp;&nbsp;&nbsp;Facebook</a> <br> -->
                <!-- <a href="https://www.youtube.com/user/apuolympics" target="_blank"><i class="fab fa-youtube"></i> Youtube</a> <br> -->
                <!-- <a href="tel:0876434343" target="_blank"><i class="fas fa-phone"></i>087-643-4343</a> <br> -->
                <!-- <a href="https://www.apu-school.com/t/?dekthai" target="_blank"><i class="fas fa-comments"></i>Line: @DekThai-online</a> <br> -->
                สอบถามรายเอียดและสมัครเรียนได้ที่ Line:
                <a href="https://www.apu-school.com/t/?line" target="_blank"> @apu-school</a>
                <br><br>
                <img
                    class="img-fluid img-thumbnail"
                    src="https://assets.dekthai-online.com/static/Linebanner.png">
            </div>
        </div>
</div>
<nav class="container-fuild navbar fixed-top  navbar-expand-lg navbar-light"
  style="background-color: #fff; box-shadow: rgb(0 0 0 / 5%) 0px 2px 28px 0px;">
  <!-- <a class="navbar-brand" [routerLink]="['teacher']" href="#home"> -->
    <a class="navbar-brand" href="/teacher">
    <img
      src="assets/instructor-dekthaicolor2.png"
      style="max-width: 220px;">
    <!-- Dekthai Online -->
  </a>
    <p class="nav-decription">ศูนย์รวมติวเตอร์คุณภาพเพื่อเด็กไทย</p>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarNavAltMarkup"
    aria-controls="navbarNavAltMarkup"
    aria-expanded="false"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- data-toggle="collapse"
  data-target="#navbarNavAltMarkup" -->

  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
    <ul class="navbar-nav ml-auto">
      <!-- <li class="nav-item">
        <a class="nav-link" routerLink="/browse">
          คอร์สเรียน/ติวเตอร์
        </a>
      </li>

      <li class="nav-item" style="display: none;">
        <a class="nav-link" routerLink="/blogs">
          บทความ
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="/contact">
          ติดต่อ</a>
      </li> -->

      <!-- <li class="nav-item" style="display: none;">
        <a class="nav-link" routerLink="/classroom">Classroom</a>
      </li> -->

      <li class="nav-item" *ngIf="!is_login" style="display: block;">
        <a class="nav-link" routerLink="/login">Login</a>
      </li>

      <!-- <li class="nav-item" *ngIf="!is_login">
        <a class="btn btn-success" routerLink="/register">
          ลงทะเบียน</a>
      </li> -->

      <li class="nav-item dropdown" style="right: 10 im !important; margin-right: 10px;" *ngIf="is_login">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{ fullname }}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">

          <!-- <a class="dropdown-item" routerLink="/dashboard"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup">My Course</a> -->

          <a class="dropdown-item" routerLink="/teacher" *ngIf="is_instructor"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup">Teacher</a>

          <a class="dropdown-item" routerLink="/admin" *ngIf="is_staff"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup">Admin</a>

          <div class="dropdown-divider"></div>
          <a class="dropdown-item" routerLink="/logout"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup">Logout</a>
        </div>
      </li>

      </ul>
  </div>
</nav>

<!-- <app-header></app-header> -->

<div style="margin-top: 60px;">
  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>

<!--
<app-home></app-home>
<app-course></app-course>
<app-contact></app-contact>
-->

<div class="container-fuild">
    <div class="container">

        <div class="row" style="margin-top: 20px;">


            <div class="col-12 col-sm-12 col-md-3 col-lg-3">

                <app-menu></app-menu>

            </div>

            <div class="col-12 col-sm-12 col-md-9 col-lg-9" style="min-height: 600px; border-left:1px solid #ccc;">
                <h5 style="border-bottom: 1px solid #ccc; padding-bottom: 15px;">การตั้งค่าข้อมูลและภาพประจำตัว</h5>

                <form>

                <div class="form-group">
                    <label for="Instructor">ชื่อสถาบัน/แบรนด์ดิ้ง</label>
                    <input type="text" class="form-control" id="Instructor" placeholder="ชื่อสถาบัน/แบรนด์ดิ้ง">
                </div>
                <!-- end -->
                <div class="form-group">
                    <label for="Instructor">ชื่อ-สกุล</label>
                    <input type="text" class="form-control" id="fullname" placeholder="ชื่อ-สกุล">
                </div>
                <!-- end -->
                <div class="form-group" style="display: none;">
                    <label for="Instructor">username</label>
                    <input type="text" class="form-control" id="username" placeholder="username">
                </div>
                <!-- end -->
                <div class="form-group">
                    <label for="Instructor">รูปแบบคอร์สเรียน</label>

                    <div style="margin-left: 50px;">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                        <label class="form-check-label" for="inlineCheckbox1">คอร์สสด/zoom</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                        <label class="form-check-label" for="inlineCheckbox2">Online</label>
                    </div>
                    </div>

                </div>
                <!-- end -->
                <div class="form-group">
                    <label for="Instructor">โปรไฟล์ย่อ</label>

                    <textarea class="form-control"></textarea>
                </div>
                <!-- end -->
                <div class="form-group">
                    <label for="Instructor">ประวัติ Profile</label>
                    <textarea rows="10" class="form-control"></textarea>
                </div>
                <!-- end -->
                    <button type="submit" class="btn btn-primary">Submit</button>
                </form>
                <hr>

                <div class="row">
                    <div class="col-4">
                        <img src="https://assets.dekthai-online.com/Ppu-400x400.jpg" class="img-fluid">
                    </div>

                    <div class="col-8">
                        <img src="https://assets.dekthai-online.com/Ppu-1280x720.jpg" class="img-fluid">
                    </div>
                </div>


                <input type="file" name="profile_image" (change)="getFile($event.target.files)"><br>
                <button (click)="fileUpload()">Upload</button>

            </div>

        </div>
    </div>
</div>



import { Component, OnInit } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConnService } from '../../conn.service';
import { AdminService } from '../../admin.service';

import { URLAPI, HTTPOPTION } from '../../app.conn';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styles: [
  ]
})
export class BlogComponent implements OnInit {

  constructor(
    private _http: HttpClient,
    private _conn: ConnService,
    private _admin: AdminService
  ) { }

  URLAPI:string;
  HTTPOPTION:any;
  authorizationData;

  ngOnInit(): void {
    this.URLAPI = URLAPI;
    this.HTTPOPTION = HTTPOPTION;
    this.authorizationData = this._conn.authorizationData();

    this.getBlogs();

  }

  blogs:[];
  async getBlogs(){
    let api = 'a2/blogsLists';
    let url = this.URLAPI + api;

    let httpOptions = {
      headers: new HttpHeaders({ 'content-type': 'application/json', 'authorization': this.authorizationData })
    };

    let response = await this._http
      .get(url, httpOptions )
      .pipe()
      .toPromise();
    // let data = response['data'];
    let blogs = response['blogs'];
    this.blogs = blogs;
  }

  update(blog, e){
    // console.warn(id);
    console.warn(blog);
    this.blogSave(blog);
  }

  async blogSave(blog){
    // console.log( this.blog );

    let api = 'a2/blogsListsSave';
    let url = this.URLAPI + api;

    let formData = blog;
    console.warn(formData);

    let httpOptions = {
      headers: new HttpHeaders({ 'content-type': 'application/json', 'authorization': this.authorizationData }),
      withCredentials: true
    };

    //{ withCredentials: true, credentials: 'include' }
    let response = await this._http
      .post(url, formData, httpOptions )
      .pipe()
      .toPromise();
  }

}

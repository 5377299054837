import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PortfolioRoutingModule } from './portfolio-routing.module';

import { IndexComponent } from './index/index.component';
import { QuizscoreComponent } from './quizscore/quizscore.component';
import { TestscoreComponent } from './testscore/testscore.component';


@NgModule({
  declarations: [IndexComponent, QuizscoreComponent, TestscoreComponent],
  imports: [
    CommonModule,
    PortfolioRoutingModule
  ]
})
export class PortfolioModule { }

import { Component, OnInit } from '@angular/core';

import { test_data_graph, test_data_table } from '../../../assets/quiz_data';

declare var google;
declare var div_chart_test;

@Component({
  selector: 'app-testscore',
  templateUrl: './testscore.component.html',
  styles: [
  ]
})
export class TestscoreComponent implements OnInit {

  test_data_table = test_data_table;
  constructor() { }

  ngOnInit(): void {
    google.charts.load('current', {'packages':['corechart']});
    google.charts.setOnLoadCallback(this.drawChart);
  }

  drawChart(){
    let dataG = test_data_graph;
    var stDetail = new google.visualization.DataTable(dataG);
	      var options = {
          chartType:"ComboChart",
          title: 'รายละเอียดการสอบของนักเรียน',
          legend: { position: 'top'},
          hAxis: { minValue: 0, slantedTextAngle: 45 },
          vAxis: {
            minValue: 0,
            viewWindow: {
              min: 0,
              max: 100
            }
          },
          //bar: {groupWidth: "20%"},
          seriesType: 'bars',
          series: {
            //0: {color: '#1ec331'},
            0: {color: '#0d8748'}, // me
            1: {type: 'line', color: '#ffd34f', lineDashStyle: [10, 2]},
            2: {type: 'line', 'color': '#0E3AD6'}
          //  2: {color: '#eda8be'}, // percentile
          },
          pointSize: 3,
          pointShape: 'circle',
          tooltip: { trigger: 'selection' },
          annotations: {
            stem: {
              color: 'transparent'
            },
            style: 'line',
            textStyle: {fontSize: 12, color: '#1D017C', opacity: 0.8 }
          }
        };

	      var charTestNo = new google.visualization.ColumnChart(div_chart_test);
	      charTestNo.draw(stDetail, options);


  }

}

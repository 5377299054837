import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ConnService } from '../../conn.service';
import { URLAPI, HTTPOPTION } from '../../app.conn';

@Component({
  selector: 'app-teacheredit',
  templateUrl: './teacheredit.component.html',
  styleUrls: ['../index/index.component.css']
})
export class TeachereditComponent implements OnInit {

  constructor(
    private _route: ActivatedRoute,
    private _http: HttpClient,
    private _formb: FormBuilder,
    private _conn: ConnService
  ) { }

    username:string;
    URLAPI:string;
    HTTPOPTION:any;
    authorizationData:any;

    form:any;
    profiles_image;
    instructor_image;
    image_asset;

    instructorForm = this._formb.group({
      id: ['', Validators.required],
      username: ['', Validators.required],
      instructor: ['', Validators.required],
      fullname: ['', Validators.required],
      //course_tag: ['', Validators.required],
      short_profiles: ['', Validators.required],
      background_profiles: ['', Validators.required],
      profiles_image: ['', Validators.required],
      instructor_image: ['', Validators.required],
    });

  ngOnInit(): void {
    this.URLAPI = URLAPI;
    this.HTTPOPTION = HTTPOPTION;
    this.authorizationData = this._conn.authorizationData();

    let sub = this._route.params.subscribe(params => {
      this.username = params['username'];
    });

    this.getInstructor(this.username);

  }

  async getInstructor(instructor_username){

    let api = 'g/Instructor'
    let params = '/' + instructor_username;
    let url = this.URLAPI+api+params;

    let httpOptions = {
      headers: new HttpHeaders({'authorization': this.authorizationData})
    };

    let response = await this._http
      .get(url, httpOptions)
      .pipe()
      .toPromise();

      //console.log(response['instructor']);

      let instructor = response['instructor'];
      this.instructorForm = this._formb.group({
        id: [instructor['id'], Validators.required],
        username: [instructor['username'], Validators.required],
        instructor: [instructor['instructor'], Validators.required],
        fullname: [instructor['fullname'], Validators.required],
        //course_tag: ['a5', Validators.required],
        short_profiles: [instructor['short_profiles'], Validators.required],
        background_profiles: [instructor['background_profiles'], Validators.required],
        profiles_image: [instructor['profiles_image'], Validators.required],
        instructor_image: [instructor['instructor_image'], Validators.required],
      });

      this.profiles_image = instructor['profiles_image'];
      this.instructor_image = instructor['instructor_image'];
      this.image_asset = instructor['image_assets'];

  }

  yourFile: File;
  getFileProfile(fileInput) {
    this.yourFile = fileInput.item(0);
    console.log(this.yourFile);
 }

  getFileInstructor(fileInput){
    this.yourFile = fileInput.item(0);
    console.log(this.yourFile);
  }

  getFileAsset(fileInput){
    this.yourFile = fileInput.item(0);
    console.log(this.yourFile);
  }

 async fileUpload(perfix){

   let httpOptions = {
     headers: new HttpHeaders({'authorization': this.authorizationData})
   };

   let folder = 'profiles';
   let api = 'g/upload/' + folder;
   let url = URLAPI + api;

   const formData: FormData = new FormData();
   formData.append('profile_image', this.yourFile, this.yourFile.name);

   let response = await this._http
     .post(url, formData, httpOptions )
     .pipe()
     .toPromise();

     let fileUpload = response;
     if(perfix == 'profiles'){
        this.profiles_image = fileUpload['image_src'];
        this.instructorForm.value['profiles_image'] = this.profiles_image;
     }

     if(perfix == 'instructor'){
        this.instructor_image = fileUpload['image_src'];
        this.instructorForm.value['instructor_image'] = this.instructor_image;
     }

  }


  async onSubmit(){
    let api = 'a2/updateInstructor';
    let url = this.URLAPI + api;

    let formData = this.instructorForm.value;
    console.warn(formData);

    let httpOptions = {
      headers: new HttpHeaders({ 'content-type': 'application/json', 'authorization': this.authorizationData })
    };

    //{ withCredentials: true, credentials: 'include' }
    let response = await this._http
      .post(url, formData, httpOptions )
      .pipe()
      .toPromise();

    // console.warn(response);

  }



}

import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';

//import { instructor } from '../../../assets/instructor'
import { URLAPI, HTTPOPTION } from '../../app.conn';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['../index/index.component.css']
})
export class ResultComponent implements OnInit {

  constructor( private _http: HttpClient, ) { }

  URLAPI:string;
  HTTPOPTION:any;

  ngOnInit(): void {
    //console.log(instructor);
    this.URLAPI = URLAPI;
    this.HTTPOPTION = HTTPOPTION;
    //this.instructor = instructor;
    this.getInstructors();
  }

  instructor:any;

  async getInstructors(){
    // console.warn('Start: getInstructor');
    let api = 'g/getInstructorLists'
    let url = this.URLAPI+api;

    let response = await this._http
      .get(url, this.HTTPOPTION)
      .pipe()
      .toPromise();
      // console.log(response);
      let instructor  = response['instructor'];
      this.instructor = instructor;

  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminRoutingModule } from './admin-routing.module';

import { IndexComponent } from './index/index.component';
import { MenuComponent } from './menu/menu.component';
import { CourseComponent } from './course/course.component';
import { TeacherComponent } from './teacher/teacher.component';
import { CourseimportComponent } from './courseimport/courseimport.component';
import { BlogComponent } from './blog/blog.component';
import { QuizComponent } from './quiz/quiz.component';
import { CourseeditComponent } from './courseedit/courseedit.component';

import { DemoMaterialModule } from './../material.module';

import { EditorModule } from '@tinymce/tinymce-angular';
import { TeachereditComponent } from './teacheredit/teacheredit.component';
import { BlogeditComponent } from './blogedit/blogedit.component';
import { CategoryComponent } from './category/category.component';



@NgModule({
  declarations: [IndexComponent, MenuComponent, CourseComponent, TeacherComponent, CourseimportComponent, BlogComponent, QuizComponent, CourseeditComponent, TeachereditComponent, BlogeditComponent, CategoryComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DemoMaterialModule,
    EditorModule
  ]
})
export class AdminModule {
  constructor() {

  }
}


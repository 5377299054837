import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ConnService } from '../../conn.service';
import { URLAPI, HTTPOPTION } from '../../app.conn';

@Component({
  selector: 'app-blogedit',
  templateUrl: './blogedit.component.html',
  styleUrls: ['../index/index.component.css']
})
export class BlogeditComponent implements OnInit {

  constructor(
    private _route: ActivatedRoute,
    private _http: HttpClient,
    private _formb: FormBuilder,
    private _conn: ConnService
  ) { }

  username:string;
  URLAPI:string;
  HTTPOPTION:any;
  authorizationData:any;

  bid:number;
  blog:any = {'id':'', 'name':'', 'content':''};

  content = '<p>&emsp;<strong>โรงเรียนกวดวิชาองอาจภารดี</strong>&nbsp;(กวดวิชาอาจารย์ปุ๊ เลขโอลิมปิก) ก่อตั้งที่จังหวัดขอนแก่น ในปี 2549 โดยอาจารย์องอาจ สุภัคชูกุล (อ.ปุ๊) อดีตตัวแทนคณิตศาสตร์โอลิมปิก (IMO) โดยโรงเรียนได้ใช้เทคโนโลยีพัฒนาระบบการเรียนการสอนที่มีคุณภาพและทันสมัย และได้พัฒนาระบบการเรียนกับคอมพิวเตอร์ตัวต่อตัวเป็นแห่งแรกของภาคอีสาน ที่นักเรียนจะคุ้นเคยกับชื่อ&nbsp;<strong>slam</strong>&nbsp;ต่อมาในปี 2558 ทางโรงเรียนก็ได้ พัฒนาระบบ ให้ก้าวหน้ามาอีกขั้นเป็น&nbsp;<strong>Platform</strong>&nbsp;การเรียนออนไลน์เต็มตัว ในชื่อใหม่ คือ&nbsp;<strong>apu-online</strong>&nbsp;ซึ่งทางโรงเรียนมั่นใจว่า ด้วยระบบนี้จะทำให้นักเรียนทั่วประเทศไทย ไม่ว่าจะเป็นจังหวัดไหน โรงเรียนไหน สามารถ&nbsp;<strong>&ldquo;เก่งขึ้นได้จริงๆ&rdquo;</strong>&nbsp;แน่นอน</p>';


  ngOnInit(): void {

    this.URLAPI = URLAPI;
    this.HTTPOPTION = HTTPOPTION;
    this.authorizationData = this._conn.authorizationData();

    let sub = this._route.params.subscribe(params => {
      this.bid = params['bid'];
    });

    this.getBlogsByID();

  }

  async getBlogsByID(){
    let bid = this.bid;

    let api = 'a2/blogsLists/'+bid;
    let url = this.URLAPI + api;

    let httpOptions = {
      headers: new HttpHeaders({ 'content-type': 'application/json', 'authorization': this.authorizationData })
    };

    let response = await this._http
      .get(url, httpOptions )
      .pipe()
      .toPromise();
    // let data = response['data'];
    let blog = response['blogs'];
    // blog['content'] = this.content;
    this.blog = blog;

    console.warn(this.blog);

  }

  async blogSave(){
    console.log( this.blog );

    let api = 'a2/blogsListsSave';
    let url = this.URLAPI + api;

    let formData = this.blog ;
    console.warn(formData);

    let httpOptions = {
      headers: new HttpHeaders({ 'content-type': 'application/json', 'authorization': this.authorizationData }),
      withCredentials: true
    };

    //{ withCredentials: true, credentials: 'include' }
    let response = await this._http
      .post(url, formData, httpOptions )
      .pipe()
      .toPromise();
  }

  yourFile: File;
  getFileProfile(fileInput) {
    this.yourFile = fileInput.item(0);
    console.log(this.yourFile);
  }

 async fileUpload(perfix){

  let httpOptions = {
    headers: new HttpHeaders({'authorization': this.authorizationData})
  };

  let folder = 'pages';
  let api = 'g/upload/' + folder;
  let url = URLAPI + api;

  const formData: FormData = new FormData();
  formData.append('profile_image', this.yourFile, this.yourFile.name);

  let response = await this._http
    .post(url, formData, httpOptions )
    .pipe()
    .toPromise();

    let fileUpload = response;
    if(perfix == 'images_assets'){

      let images_assets = this.blog['images_assets'];
      images_assets.push(fileUpload['image_src']);
      console.warn(images_assets);

      this.blog['images_assets'] =  images_assets;
      this.blogSave();
    }



 }
}

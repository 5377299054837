<div class="container-fuild">

    <div class="row" style="margin-top: 20px;">
        <div class="col-12 col-sm-12 col-md-2 col-lg-2">
            <app-menu></app-menu>
            <img
                [src]="quiz_event['bg']"
                class="img-fluid img-thumbnail" style="width: 100%;">
        </div>

        <div class="col-12 col-sm-12 col-md-10 col-lg-10" style="min-height: 600px; border-left:1px solid #ccc;">
            <h2 style="border-bottom: 1px solid #ccc; padding-bottom: 15px;">
                Quiz Battle
            </h2>

            <button
                class="btn btn-danger btn-sm"
                style="position: fixed; top: 100px; right: 50px; z-index: 1000;"
                (click)="closeAllSeq()">
                close receive answer All : {{ solutionCnt }} <span *ngIf="secAll">({{ secAll }})</span>
            </button>
            <!-- {{ quiz_event | json}} -->

            <div class="row">
                <div class="col-6">
                    <mat-form-field class="example-full-width">
                        <mat-label>Chapter</mat-label>
                        <input matInput placeholder="Chapter name" [(ngModel)]="quiz_event['name']">
                    </mat-form-field>
                </div>
                <div class="col-2">
                    <mat-form-field class="example-full-width">

                        <mat-label>Date</mat-label>
                        <input matInput [matDatepicker]="picker" [(ngModel)]="quiz_event['created_time']">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>

                    </mat-form-field>

                </div>

                <div class="col-6">
                    <mat-form-field class="example-full-width">
                        <mat-label>Detail</mat-label>
                        <input matInput placeholder="รายละเอียด หัวข้อประเมิน (เช่น ตะลุยโจทย์ข้อสอบเก่า ปี 63, การแยกตัวประกอบ, คำพ้องรูป)"
                                [(ngModel)]="quiz_event['description']">
                    </mat-form-field>
                </div>

                <div class="col-5" style="padding-top: 15px;">
                    <button
                        class="btn btn-info btn-sm"
                        (click)="saveEvent()">save</button>

                    <button
                        class="btn btn-info btn-sm"
                        data-toggle="modal" data-target=".student_today_result"
                        (click)="quizStudentToday()">list of students</button>
                    <!-- <button class="btn btn-primary btn-sm">เพิ่มคะแนน Extra</button> -->

                    <button class="btn btn-warning btn-sm"
                        data-toggle="modal" data-target=".quizExtra"
                        (click)="quizExtra()">Extra</button>

                    <button class="btn btn-primary btn-sm"
                        data-toggle="modal" data-target=".summary_result"
                        (click)="quizDetailToday()">Quiz's Result</button>


                </div>
                <div class="col-1 texe-left">
                    <mat-spinner *ngIf="loading" diameter="30" ></mat-spinner>
                </div>

            </div>
            <hr>

            <div class="row" *ngFor="let quiz of quiz_detail; index as i">

                <div class="col-1 text-center">

                    <mat-form-field class="example-full-width">
                        <mat-label>Seq</mat-label>
                        <input matInput disabled
                            [(ngModel)]="quiz_detail[i]['seq']">
                      </mat-form-field>


                </div>
                <div class="col-2">
                    <mat-form-field class="example-full-width">
                        <mat-label>Answer</mat-label>
                        <input matInput [(ngModel)]="quiz_detail[i]['sol']">
                    </mat-form-field>
                </div>

                <div class="col-1">
                    <span style="color: #ccc; font-size: 0.7em;">Receive</span>
                    <mat-slide-toggle
                        (change)="updateFlag(i, $event)"
                        [(ngModel)]="quiz_detail[i]['flag']"
                    >{{ quiz_detail[i]['letters'] }}</mat-slide-toggle>
                </div>
                <div class="col-1">
                    <span style="color: #ccc; font-size: 0.7em;">Writing</span>
                    <mat-slide-toggle
                        (change)="updateFlagWriting(i, $event)"
                        [(ngModel)]="quiz_detail[i]['quiz_type']">&nbsp;
                    </mat-slide-toggle>
                </div>

                <div class="col-2 text-center">
                    <br>
                    <button
                        class="btn btn-info btn-sm"
                        data-toggle="modal" data-target=".modal-lg-writingSt"
                        [disabled]="!quiz_detail[i]['quiz_type']"
                        (click)="studentwriting(i)">review</button>

                    <button
                        class="btn btn-primary btn-sm"
                        data-toggle="modal" data-target=".modal-lg-resultSeq"
                        (click)="updateSolution(i)">Check</button>
                        <!-- data-toggle="modal" data-target=".modal-lg-resultSeq" -->
                </div>


                <div class="col-1">
                    <mat-form-field class="example-full-width">
                        <mat-label>Ans: 2 (option)</mat-label>
                        <input matInput [(ngModel)]="quiz_detail[i]['sol2']">
                    </mat-form-field>
                </div>
                <div class="col-1">
                    <mat-form-field class="example-full-width">
                        <mat-label>Ans 3 (option)</mat-label>
                        <input matInput [(ngModel)]="quiz_detail[i]['sol3']">
                    </mat-form-field>
                </div>
                <div class="col-2">
                    <mat-form-field class="example-full-width">
                        <mat-label>Note</mat-label>
                        <input matInput [(ngModel)]="quiz_detail[i]['note']">
                    </mat-form-field>
                </div>

                <div class="col-12" style="display: none;">
                    {{ quiz_detail[i] | json}}
                </div>

            </div>

        </div>

    </div>

</div> <!-- end container-fuild -->

<!-- Large modal -->
<div class="modal fade modal-lg-resultSeq" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">


  <div class="modal-dialog modal-lg" style="max-width: 1200px;">
    <div class="modal-content">

        <div class="modal-header" style="background-color: #009688; color: #fff; padding: 0.8rem 1rem;">
            <h2 class="modal-title" id="exampleModalLabel">Quiz Result: {{ QD['name'] }} Seq. {{ QD['quiz_seq'] }}</h2>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

        <div class="row" style="margin-bottom: 15px; padding: 15px;">
            <div class="col-3 text-left">

                <h2 class="text-center"><strong>Difficult</strong></h2>
                <img src="{{QD['percent_correct_img']}}" class="img-fluid"> <br>
                <p class="text-center"><strong>Right {{ QD['percent_correct'] }}%</strong></p>

                <hr>
                <h2 class="text-center"><strong>Engagement</strong></h2>
                <img src="{{QD['engagement_img']}}" class="img-fluid"> <br>
                <p class="text-center"><strong>Engagement {{ QD['engagement'] }}%</strong></p>

                <hr>
                <p>Right 's answer {{ QD['solList'] }}</p>
                <p>Statistics of answers </p>

                <li *ngFor="let ans of QD['answerStat']"
                    style="background-color: {{ ans['color'] }}; list-style-type: ' '; padding-left: 5px;">
                    <small>
                    <strong>answer: </strong> {{ ans['answer'] }}
                    | <strong>cnt.</strong> {{ ans['percent'] }}% ({{ ans['cnt'] }} student.)
                    </small>
                </li>

            </div>

            <div class="col-9" style="margin-top: 15px; margin-bottom: 100;">

                <div class="row" style="width: 100%; background-color: #60d3d6;">
                    <div class="col-sm-12">
                        <strong>rights all students</strong>
                    </div>
                    <div class="col-sm-2 text-center" *ngFor="let stT of stTrue"
                        style="padding: 2px; height: 150px;"
                        >

                        <div class="card" style="background-color: rgba(255, 255, 255, 0); border: 0px;">
                            <div class="card-body" style="padding: 2px;">

                              <img
                                    *ngIf="stList[ stT['student_id'] ]['show_each_quiz_flag']"
                                    src="http://slamroom.apu-online.com/images/photo_profile/{{ stList[stT['student_id']]['img'] }}.jpg"
                                    class="img-fluid"
                                    style="height: 140px;"
                                    height="140px">

                                    <span *ngIf="!stList[ stT['student_id'] ]['show_each_quiz_flag']">
                                        <img
                                        src="{{stList[ stT['student_id'] ]['school_img']}}"
                                        class="img-fluid"
                                        style="height: 140px;">
                                    </span>

                                <!-- <img
                                    *ngIf="!stList[ stT['student_id'] ]['show_each_quiz_flag']"
                                    src="assets/images/privacy.jpg"
                                    class="img-fluid"
                                    style="height: 140px;"
                                    height="140px"> -->


                                <div class="overlay">
                                    <p class="card-text text-center"
                                        *ngIf="stList[ stT['student_id'] ]['show_each_quiz_flag']"
                                    >
                                        {{ stT['student_id'] }} {{ stList[stT['student_id']]['nickname'] }}<br>
                                        {{ stList[stT['student_id']]['classroom'] }} | {{ stT['pts'] }}pts.<br>
                                        {{ stList[stT['student_id']]['school'] }}
                                    </p>
                                    <p class="card-text text-center"
                                        *ngIf="!stList[ stT['student_id'] ]['show_each_quiz_flag']"
                                    >
                                        <br>
                                        {{ stList[stT['student_id']]['classroom'] }} | {{ stT['pts'] }}pts.<br>
                                        {{ stList[stT['student_id']]['school'] }}
                                    </p>
                                </div>
                            </div>
                          </div>
                    </div>
                </div>
                <!-- {{ stStat | json }}<br> -->
                <small>
                    <span style="background-color: rgb(10 164 10); padding-left: 5px; padding-right: 5px; margin-right: 3px;">right: {{ stStat['right'] }}</span>
                    <span style="background-color: #fff048; padding-left: 5px; padding-right: 5px; margin-right: 3px;">wrong: {{ stStat['wrong'] }}</span>
                    <span style="background-color: #ccc; padding-left: 5px; padding-right: 5px; margin-right: 3px;">wait: {{ stStat['wait'] }}</span>
                    <span style="background-color: #60a2d6; padding-left: 5px; padding-right: 5px; margin-right: 3px;">total: {{ stStat['total'] }}</span>
                </small>

                <hr>
                <button
                    class="btn btn-primary btn-sm"
                    (click)="showTeacherData()">For Teacher</button>
                <hr>

                <div *ngIf="showTeacher" class="row" style="width: 100%; background-color: rgb(204 243 235);">
                    <div class="col-sm-12">
                        <strong>rights all students (Teacher.)</strong>
                    </div>
                    <div class="col-sm-2 text-center" *ngFor="let stT of stTrue" style="padding: 2px; height: 150px;">
                        <div class="card" style="background-color: rgba(255, 255, 255, 0); border: 0px;">
                            <div class="card-body" style="padding: 2px;">
                              <img
                                    src="http://slamroom.apu-online.com/images/photo_profile/{{ stList[stT['student_id']]['img'] }}.jpg"
                                    class="img-fluid"
                                    style="height: 140px;"
                                    height="140px">
                                <div class="overlay">
                                <p class="card-text text-center">
                                    {{ stT['student_id'] }} {{ stList[stT['student_id']]['nickname'] }}<br>
                                    {{ stList[stT['student_id']]['classroom'] }} | {{ stT['pts'] }}pts.<br>
                                    {{ stList[stT['student_id']]['school'] }}
                                </p>
                                </div>
                            </div>
                          </div>
                    </div>
                </div>


                <div *ngIf="showTeacher" class="row" style="width: 100%; background-color: #fff8aa">
                    <div class="col-sm-12">
                        <strong>wait for answer</strong>
                    </div>
                    <div class="col-sm-2 text-center" *ngFor="let sid of stWait" style="padding: 2px; height: 150px;">

                        <div class="card" style="background-color: rgba(255, 255, 255, 0); border: 0px;">
                            <div class="card-body" style="padding: 2px;">
                              <img
                                    src="http://slamroom.apu-online.com/images/photo_profile/{{ stList[ sid ]['img'] }}.jpg"
                                    class="img-fluid"
                                    style="height: 140px;"
                                    height="140px">
                                <div class="overlay">
                                <p class="card-text text-center">
                                    {{ sid }} {{ stList[ sid ]['nickname'] }}<br>
                                    {{ stList[ sid ]['classroom'] }}<br>
                                    {{ stList[ sid ]['school'] }}
                                </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div *ngIf="showTeacher" class="row" style="width: 100%; background-color: rgb(253 206 235);">
                    <div class="col-sm-12">
                        <strong>wrong all students</strong>
                    </div>
                    <div class="col-sm-2 text-center" *ngFor="let stT of stWrong" style="padding: 2px; height: 150px;">
                        <div class="card" style="background-color: rgba(255, 255, 255, 0); border: 0px;">
                            <div class="card-body" style="padding: 2px;">
                              <img
                                    src="http://slamroom.apu-online.com/images/photo_profile/{{ stList[stT['student_id']]['img'] }}.jpg"
                                    class="img-fluid"
                                    style="height: 140px;"
                                    height="140px">
                                <div class="overlay">
                                <p class="card-text text-center">
                                    {{ stT['student_id'] }} {{ stList[stT['student_id']]['nickname'] }}<br>
                                    {{ stList[stT['student_id']]['classroom'] }}<br>
                                    ans: {{ stT['answer'] }}
                                </p>
                                </div>
                            </div>
                          </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
  </div>
</div>



<!-- Large modal -->

<div class="modal fade summary_result" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="max-width: 1200px;">
        <div class="modal-content mat-typography">

            <div class="modal-header" style="background-color: #009688; color: #fff; padding: 0.8rem 1rem;">
                <h2 class="modal-title" id="exampleModalLabel">
                    Result Summary: {{ quiz_event['name'] }}
                </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>


            <div class="container-fuild">

<img src="assets/images/apuquizicon.png" width="100px" align="left" style="margin: 10px;">
<img src="assets/dekthai-logo.png" width="220px" align="right" style="margin: 10px;">

<p style="font-size: +1.4em; line-height: 1.5;">
    {{ quiz_event['description'] }}
</p>

<p style="font-size: +1.2em; line-height: 1.5;">
    Statistics | Maximum Score: {{ quiz_battel_result['max_percent'] }}% | Minimum Score: {{ quiz_battel_result['min_percent'] }}% | Persons: {{ quiz_battel_result['student_cnt'] }} <br>
    Average score: {{ quiz_battel_result['avg_percent'] }}% | S.D.: {{ quiz_battel_result['sd_percent'] }}%
</p>

<p style="font-size: +1.2em;">Challengers' T-score (by DekThai-Online)</p>


<div class="row" style="width: 100%; text-align: center; background-color: #d468ac; margin-left: 5px;">

    <div class="col-sm-1 quiz_tscore_result text-center" *ngFor="let stT of student_tscore_result "
        style="padding: 2px; height: 150px;"
        >
        <div class="card" style="background-color: rgba(255, 255, 255, 0); border: 0px;">
            <div class="card-body" style="padding: 2px;">
                <img
                    *ngIf="stList[ stT['sid'] ]['show_tscore_flag']"
                    src="http://slamroom.apu-online.com/images/photo_profile/{{ stList[stT['sid']]['img'] }}.jpg"
                    class="img-fluid"
                    style="height: 140px;">
                </div>

                <span *ngIf="!stList[ stT['sid'] ]['show_tscore_flag']">
                <img
                    *ngIf="!stList[ stT['sid'] ]['show_tscore_flag']"
                    src="{{stList[ stT['sid'] ]['school_img']}}"
                    class="img-fluid"
                    style="height: 140px;">

                </span>

                <div class="overlay">
                    <p class="card-text text-center"
                        *ngIf="stList[ stT['sid'] ]['show_tscore_flag']"
                    >
                        {{ stT['sid'] }} {{ stList[stT['sid']]['nickname'] }}<br>
                        {{ stList[stT['sid']]['classroom'] }} | {{ stT['pts'] }}<br>
                        {{ stList[stT['sid']]['school'] }}
                    </p>

                    <p class="card-text text-center"
                        *ngIf="!stList[ stT['sid'] ]['show_tscore_flag']"
                    >
                        <br>
                        {{ stList[stT['sid']]['classroom'] }} | {{ stT['pts'] }}<br>
                        {{ stList[stT['sid']]['school'] }}
                    </p>

                </div>


          </div>
    </div>

</div>

<!-- rgb(204 243 235) -->
<hr>
<button
    class="btn btn-primary btn-sm"
    (click)="showTeacherData()">ดู T-Score นักเรียนทั้งหมด</button>
<hr>

<div *ngIf="showTeacher" class="row" style="width: 100%; text-align: center; background-color: rgb(204 243 235); margin-left: 5px;">

    <div class="col-sm-1 quiz_tscore_result text-center" *ngFor="let stT of student_tscore_result "
        style="padding: 2px; height: 150px;"
        >
        <div class="card" style="background-color: rgba(255, 255, 255, 0); border: 0px;">
            <div class="card-body" style="padding: 2px;">
                <img
                    src="http://slamroom.apu-online.com/images/photo_profile/{{ stList[stT['sid']]['img'] }}.jpg"
                    class="img-fluid"
                    style="height: 140px;">
                </div>

                <div class="overlay">
                    <p class="card-text text-center">
                        {{ stT['sid'] }} {{ stList[stT['sid']]['nickname'] }}<br>
                        {{ stList[stT['sid']]['classroom'] }} | {{ stT['pts'] }}<br>
                        {{ stList[stT['sid']]['school'] }}
                    </p>
                </div>
          </div>
    </div>

</div>

            </div>
        </div>
    </div>
</div>


<!-- Large modal -->
<div class="modal fade student_today_result" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="max-width: 1200px;">
        <div class="modal-content mat-typography">

            <div class="modal-header" style="background-color: #007bff; color: #fff; padding: 0.8rem 1rem;">
                <h2 class="modal-title" id="exampleModalLabel">
                    Students today {{ quiz_event.name }}<br>
                    Date {{ quiz_event.dateTh }} ({{ops_quiz_event.come}} คน)
                </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

    <!-- นักเรียนที่มาเรียน -->

<div style="margin: 10px;">

<div class="row" style="width: 100%; text-align: center; background-color: #00edff4a; margin-left: 5px;">
    <div class="col-sm-1 quiz_tscore_result text-center" *ngFor="let sid of stToday"
        style="padding: 2px; height: 150px;"
        >

        <div class="card" style="background-color: rgba(255, 255, 255, 0); border: 0px;">
            <div class="card-body" style="padding: 2px;">
                <img
                    src="http://slamroom.apu-online.com/images/photo_profile/{{ stListRoom[ sid ]['img'] }}.jpg"
                    class="img-fluid"
                    style="height: 140px;">

                <div class="overlay">
                    <p class="card-text text-center">
                        {{ sid }} {{ stListRoom[ sid ]['nickname'] }}<br>
                        {{ stListRoom[ sid ]['classroom'] }}<br>
                        {{ stListRoom[ sid ]['school'] }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- นักเรียนที่ไม่ได้มาเรียน -->
<div class="row">

    <div class="col-sm-12">
    <hr>
    <button
        class="btn btn-primary btn-sm"
        (click)="showTeacherData()">ดูรายละเอียดสำหรับคุณครู</button>
    </div>
    <hr>
</div>

        <div  *ngIf="showTeacher"  class="row"
            style="width: 100%;
            text-align: center;
            background-color: #eeeeee;
            margin-left: 5px;
            margin-top: 15px;">
            <div class="col-sm-12 text-left">
                <strong>นักเรียนที่ไม่ได้เข้าเรียนวันนี้</strong>
            </div>
            <div class="col-sm-1 quiz_tscore_result text-center" *ngFor="let sid of stNotToday"
                style="padding: 2px; height: 150px;">

                <div class="card" style="background-color: rgba(255, 255, 255, 0); border: 0px;">
                    <div class="card-body" style="padding: 2px;">
                        <img
                            src="http://slamroom.apu-online.com/images/photo_profile/{{ stListRoom[ sid ]['img'] }}.jpg"
                            class="img-fluid"
                            style="height: 140px;">

                        <div class="overlay">
                            <p class="card-text text-center">
                                {{ sid }} {{ stListRoom[ sid ]['nickname'] }}<br>
                                {{ stListRoom[ sid ]['classroom'] }}<br>
                                {{ stListRoom[ sid ]['school'] }}
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>

</div>

        </div>
    </div>
</div>



<!-- modle-writing -->

<!-- Large modal -->
<div class="modal fade modal-lg-writingSt" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="max-width: 1200px;">
        <div class="modal-content mat-typography">

            <div class="modal-header" style="background-color: #007bff; color: #fff; padding: 0.8rem 1rem;">
                <h2 class="modal-title" id="exampleModalLabel">
                    Students answer {{ quiz_event.name }}
                </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

    <!-- นักเรียนที่มาเรียน -->

            <div style="margin: 10px;">


                <div
                    class="row"
                    *ngFor="let sq of stAnswer; index as i"
                >

                    <div class="col-1">
                        <mat-form-field class="example-full-width">
                            <input matInput value="{{ i + 1 }}" disabled>
                        </mat-form-field>
                    </div>

                    <div class="col-1">
                        <mat-form-field class="example-full-width">
                            <input
                                matInput
                                value="{{ sq ['sid'] }}"
                                style="color: #000000;"
                                disabled>
                        </mat-form-field>
                    </div>

                    <div class="col-1" style="margin-top: 10px;">

                        <mat-slide-toggle
                            [(ngModel)]="stAnswer[i]['flag']"
                        ></mat-slide-toggle>
                        <!-- (change)="updateFlag(i, $event)"
                            [(ngModel)]="quiz_detail[i]['flag']" -->
                    </div>

                    <div class="col-6">
                        <mat-form-field class="example-full-width">
                            <input
                                matInput
                                value="{{ sq ['answer_text'] }}"
                                style="color: #000000;"
                                disabled>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <a
                            href="#"
                            data-toggle="modal"
                            data-target="#exampleModal"
                            (click)="answer_image( sq['image_scr'] )">
                            <img [src]="sq ['image_scr']" style="height: 100px;">
                        </a>

                    </div>

                </div>

                <div class="row">
                    <div class="col-3">

                    </div>
                    <div class="col-6">
                        <button
                        class="btn btn-primary btn-sm"
                        (click)="upScoreText()"
                        data-dismiss="modal" aria-label="Close"
                        data-toggle="modal" data-target=".modal-lg-resultSeq"
                    >Update Student Score </button>
                    </div>
                    <!-- {{ stAnswer | json }} -->
                </div>

                <!-- {{ stAnswer | json }} -->


            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel" style="font-size: 1.2em;">Answer' Picture</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

            <img [src]="image_src" style="width: 100%;">

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>


  <!-- Large modal -->
<div class="modal fade quizExtra" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="max-width: 1200px;">
        <div class="modal-content mat-typography">

            <div class="modal-header" style="background-color: #007bff; color: #fff; padding: 0.8rem 1rem;">
                <h2 class="modal-title" id="exampleModalLabel">
                    Extra Score
                </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

    <!-- นักเรียนที่มาเรียน -->

            <div style="margin: 10px;">

                {{ stToday | json }}

                <div class="row">

                    <div class="col-md-2" *ngFor="let sid of stToday">
                        <div class="custom-control custom-checkbox image-checkbox">
                            <input type="checkbox" class="custom-control-input" id="{{sid}}">
                            <label class="custom-control-label" for="{{sid}}">
                                <img src="https://slamroom.apu-online.com/images/photo_profile/{{sid}}.jpg" alt="#" class="img-fluid">
                            </label>
                        </div>
                    </div>

                </div>



            </div>
        </div>
    </div>
</div>





<!-- <button class="btn btn-danger btn-sm" (click)="triggerModal(modalData)">
    Open Modal
</button>

<ng-template #modalData let-modal>


</ng-template> -->


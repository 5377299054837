import { Component } from '@angular/core';
// import { CookieService } from 'ngx-cookie-service';

import { ConnService } from './conn.service';

declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})



export class AppComponent {

  title = 'dekthai-online';
  is_login    = false;

  user;
  is_staff    = false;
  is_instructor = false;
  instructor_id = 0;
  username;
  fullname;


  constructor(
    private _conn: ConnService
  ) {

    // this._conn.get();
    this.getConn();

  }

  async getConn(){
    await this._conn.get();
    this.checkUSER();
  }

  checkUSER(){

    let user = this._conn.getCookie();
    // console.warn(user);
    if(user['userid'] > 0){
      // console.warn(user);
      this.is_login = true;
      this.username = user['user'];
      this.fullname = user['fullname'];
      this.is_staff = user['is_staff'];
      this.instructor_id = user['instructor_id'];

      if(Number(this.is_staff) > 0){
        this.is_staff = true;
      }
      if(Number(this.instructor_id) > 0 ){
        this.is_instructor = true;
      }
    }

  }
}

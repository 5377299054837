<div class="container-fuild">

        <div class="row" style="margin-top: 20px;">
            <div class="col-12 col-sm-12 col-md-2 col-lg-2 text-center">
                <app-menu></app-menu>
            </div>

            <div class="col-12 col-sm-12 col-md-10 col-lg-10" style="min-height: 600px; border-left:1px solid #ccc;">
                <h2 style="border-bottom: 1px solid #ccc; padding-bottom: 15px;">
                  <i class="fa fa-list" aria-hidden="true"></i> Quiz Battle
                </h2>

                <form class="example-form">

                  <div class="row">

                    <div class="col-4">
                        <mat-form-field class="example-full-width">
                        <mat-label>Teacher: ({{ ops_teacher_name }})</mat-label>
                        <input
                            matInput disabled
                            value="{{ fullname }}">
                        </mat-form-field>
                    </div>
                    <div class="col-8">
                      <mat-form-field class="example-full-width">
                        <mat-label>Class Or Chapter</mat-label>
                        <input type="text"
                               placeholder="Pick one"
                               aria-label="บทเรียน Chapter" matInput
                               [formControl]="formChapter"
                               [matAutocomplete]="auto"
                               >
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            {{option}}
                          </mat-option>
                        </mat-autocomplete>

                      </mat-form-field>
                    </div>

                    <div class="col-8">

                    </div>

                    <div class="col-3 text-right">
                      <button
                        class="btn btn-primary"
                        (click)="showChapterQuiz()">
                        <i class="fa fa-align-justify" aria-hidden="true"></i> List Quizzes
                      </button>
                    </div>

                    <div class="col-1 texe-left">
                      <mat-spinner *ngIf="loading" diameter="30" ></mat-spinner>
                    </div>

                  </div>

                </form>
<hr>


                  <table class="table table-bordered" *ngIf="quizTable" >
                    <thead class="thead-light">
                      <tr>
                        <th class="text-center" scope="col" width="70px;">Seq</th>
                        <th class="text-center" scope="col">Detail</th>
                        <th class="text-center" scope="col" width="160px;">Date</th>
                        <th class="text-center" scope="col" width="60px">Active</th>
                        <th class="text-center" scope="col" width="160px;">
                          <!-- <button
                            mat-mini-fab
                            color="accent"
                            aria-label="Example icon button with a menu icon"
                            data-toggle="modal" data-target="#exampleModalCenter"
                            >
                            <mat-icon>plus_one</mat-icon>
                          </button> -->

                          <button
                            class="btn btn-primary"
                            data-toggle="modal" data-target="#exampleModalCenter"
                            [disabled]="addQuizShow">
                            <i class="fa fa-plus-square" aria-hidden="true"></i> Add Quiz
                          </button>

                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let quizs of quizLists">
                        <td class="text-center" scope="row">
                          {{ quizs['seq'] }}
                        </td>
                        <td>
                          <!-- <a href="/teacher/quiz/{{quizs['id']}}" target="_blank">{{ quizs['description'] }}</a> -->
                          <a routerLink="/teacher/quiz/{{quizs['id']}}" target="_blank">{{ quizs['description'] }}</a>

                        </td>
                        <td class="text-center">{{ quizs['dateEn'] }}</td>
                        <td class="text-center">
                          <mat-slide-toggle (change)="slideSwitch(quizs['id'], $event)"
                            [(ngModel)]="quizs['flag_c']"
                          >
                          </mat-slide-toggle>
                        </td>
                        <td class="text-center">
                          <img
                            [src]="quizs['bg']"
                            class="img-fluid img-thumbnail" style="width: 100%;">
                        </td>
                      </tr>

                    </tbody>
                  </table>



            </div>

        </div>

</div>


<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLongTitle">
          Create Quiz: {{ chapterSelected }}
        </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="row">
<!--
          <div class="col-12">
            <mat-form-field class="example-full-width">
              <mat-label>ชื่อ Quiz </mat-label>
              <input type="text"
                placeholder="ชื่อควิซ "
                aria-label="ชื่อควิซ" matInput
                [formControl]="formQuizName">
            </mat-form-field>
          </div> -->

          <div class="col-12">
            <mat-form-field class="example-full-width">
              <mat-label>Quiz Detail (for report)</mat-label>
              <input type="text"
                placeholder="Quiz Detail"
                aria-label="รายละเอียด หัวข้อประเมิน" matInput
                [formControl]="formQuizDescription">
            </mat-form-field>

          </div>


          <div class="col-4">
            <!--
              <mat-form-field appearance="fill">
              <mat-label>วันที่ Quiz</mat-label>
              <input matInput [matDatepicker]="picker">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            -->
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="createQuiz()">Create Quiz</button>
      </div>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DetailComponent } from './detail/detail.component';
import { RecommendComponent } from './recommend/recommend.component';
import { PurchaseComponent } from './purchase/purchase.component';

const routes: Routes = [
  { path : 'course/:ops_course_id', component : DetailComponent },
  { path : 'course/purchase/:ops_course_id', component : PurchaseComponent },
//  {path : 'quizlists/:sid', component : QuizlistsComponent},
//  {path : 'quiz/:sid/:qid/:hash', component : QuizComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CourseRoutingModule { }

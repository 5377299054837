import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InstructorsRoutingModule } from './instructors-routing.module';
import { SiteModule } from './../site/site.module';

import { InstructorComponent } from './instructor/instructor.component';
import { CourselistsComponent } from './courselists/courselists.component';

// import { HalloffameComponent } from './../site/halloffame/halloffame.component';

@NgModule({
  declarations: [InstructorComponent, CourselistsComponent],
  imports: [
    CommonModule,
    InstructorsRoutingModule,
    SiteModule
  ]
})
export class InstructorsModule { }

<div class="container-fuild">
    <div class="container">
        <div class="row" style="margin-top: 15px;">
            <div class="col-12">
                <h2>วิธีการสมัครเรียน</h2><hr>
                <h5><u>ขั้นตอนการสมัครเรียน</u></h5>
            </div>

            <div class="col-12 text-center" >
                    <img
                        style="max-width: 600px;"
                        class="img-fluid"
                        src="https://assets.dekthai-online.com/upload/2021/04/24/20210424042507-af154b55.jpg">
            </div>
        </div>
    </div>
</div>
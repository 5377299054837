import { Component, OnInit } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormControl, FormBuilder, Validators } from '@angular/forms';

import { ConnService } from '../../conn.service';
import { URLAPI, HTTPOPTION } from '../../app.conn';


@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styles: [
  ]
})
export class ProfilesComponent implements OnInit {

  constructor(
    private _cookieService: CookieService,
    private _formb: FormBuilder,
    private _http: HttpClient,
    private _conn: ConnService
    ) { }

    authorizationData;
    form:any;
    loginForm = this._formb.group({
      instructor: ['', Validators.required],
      fullname: ['', Validators.required],
      username: ['', Validators.required],
      short_profiles: ['', Validators.required],
      background_profiles: ['', Validators.required],
      course_tag: ['', Validators.required]
    });
    yourFile: File;

  ngOnInit(): void {
    this.authorizationData = this._conn.authorizationData();

  }

  getFile(fileInput) {
     this.yourFile = fileInput.item(0);
     console.log(this.yourFile);
  }

  async fileUpload(){
    //alert('123');
    console.warn('fileUpload');
    console.warn(this.yourFile);

    let httpOptions = {
      headers: new HttpHeaders({'authorization': this.authorizationData})
    };

    let api = 'g/upload';
    let url = URLAPI + api;

    const formData: FormData = new FormData();
    formData.append('profile_image', this.yourFile, this.yourFile.name);

    let response = await this._http
      .post(url, formData, httpOptions )
      .pipe()
      .toPromise();

      console.warn(response);



  }


}

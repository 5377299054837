<hr>
<div class="row search-subject-zoom">
    <div class="col">
        <h4>คอร์สที่เกี่ยวข้อง</h4>
    </div>
</div>


<div class="row" style="background-color: #fff;">

    <div class="col-12 col-sm-6 col-md-6 col-lg-4" *ngFor="let c of crelation">
      <div class="browse_item">

        <img class="browse_item_cover"
            src="{{c['course_image']}}"
            width="100%">

        <div class="browse_item_body">

            <span
                style="font-size: 0.8em; font-weight: normal; margin-top: 20px; color:#3c832a;">
                    Online 100%
            </span>

            <h5>{{c['name']}}</h5>

            <small>{{c['info']}}</small>

            <div class="browse_item_images_footer">

              <a *ngFor="let ins of c['instructor_id']" href="/instructor/{{ins[1]}}">
              <img
                  src="{{ins[3]}}"
                  alt="profiles"
                  data-toggle="tooltip" data-placement="top" title="{{ins[2]}}"
                  class="instructor_image" >
              </a>

          </div>

        </div>

        <div class="browse_item_footer">
            ราคา {{c['price'] | number:0}}<small>บาท</small>

          <span style="position: fixed; right: 10px;">
            <a href="course/{{c['ops_course_id']}}"  class="btn btn-primary btn-sm">ดูรายละเอียด</a>
          </span>
        </div>
      </div>        <!-- end browse_item -->

    </div>          <!-- end col -->

  </div>
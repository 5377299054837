<div class="container-fuild">
    <div class="container">
        <div class="row"
            style="
                margin-top: 15px;
                padding-top: 20px;
                padding-bottom: 20px;
                background-color: rgb(31 44 121);
                border-radius: 10px;
                color: #fff;">

            <div class="col-12 col-sm-12 col-md-12 col-lg-8" style="margin-bottom: 5px;">
                <img
                    [src]="course?.course_image"
                    style="
                        width: 100%;
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                        border-radius: 10px;
                        box-shadow: 0 0 1px rgb(90 90 90);
                        "
                    >
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-4" style="margin-bottom: 5px;">
                <div style="
                        background-color: #fff;
                        width: 100%;
                        height: 100%;
                        padding: 20px;
                        border-radius: 10px;
                        box-shadow: 0 0 1px rgb(90 90 90);
                        color: #000;">
                <span
                    style="font-size: 0.8em; font-weight: normal; margin-top: 20px; color:#3c832a;">
                    {{ course?.course_type }}
                </span>
                <div style="min-height: 90px;">
                    <p style="margin-top: 10px; font-size: 1.5em;">{{ course?.name }}</p>
                </div>

                <p>
                    <i class="far fa-clock"></i> ชั่วโมงเรียนจริง {{ course?.realtime }}<small>ชั่วโมง</small>
                </p>
                <p *ngIf="course?.timelimit_semi != 0">
                    <i class="far fa-clock"></i> คอร์ส Zoomสด <small>ชดเชยออนไลน์ได้</small> {{ course?.timelimit_semi }}<small>ชั่วโมง</small>
                </p>
                <p *ngIf="course?.timelimit != 0">
                    <i class="far fa-clock"></i> คอร์ส online100% <small>เรียนออนไลน์ได้</small> {{ course?.timelimit }}<small>ชั่วโมง</small>
                </p>

                <p>
                    <i class="far fa-calendar-minus"></i> อายุคอร์ส {{ course?.daylimit }}<small>วัน</small>
                </p>

                <p style="font-size: 1.3em;">ราคา {{ course?.price }}<small>บาท</small></p>
                <hr>
                <!-- <a
                    style="width: 100%;"
                    type="button"
                    class="btn btn-info"
                    routerLink="/course/purchase/Uxxx">สมัครเรียนเลย</a> -->

                    <button
                    style="width: 100%;"
                    type="button"
                    class="btn btn-info"
                    (click)="scrollTo()">สมัครเรียนเลย</button>

                </div>
            </div>
        </div>

        <div class="row"
            style=" margin-top: 20px;
                    margin-top: 15px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    background-color: #f1f3ff;
                    border-radius: 10px;">

            <div class="col-12 col-sm-8 col-md-9 col-lg-9" style="margin-top: 10px;">
                <p style="font-size: 1.3em;">รายละเอียด</p>
                <p>
                    {{ course?.info }}
                </p>
                <hr>

                <div [innerHTML]="content"></div>

            </div>

            <div class="col-12 col-sm-4 col-md-3 col-lg-3 text-center" style="margin-top: 10px;">

                <div style="margin-bottom: 15px;">
                <img
                    [src]="course?.instructor['profiles_image']"
                    alt="profiles"
                    style="box-sizing: border-box;
                    min-width: 0px;
                    margin-bottom: 15px;
                    width: 50%;
                    max-width: 100%;
                    height: auto;
                    border-radius: 100%;
                    box-shadow: rgb(0 0 0 / 5%) 0px 2px 28px 0px;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;"
                    >
                    <strong><a href="/instructor/{{ course?.instructor['username'] }}">{{ course?.instructor['fullname'] }}</a></strong><br>
                        <small>
                            {{ course?.instructor['short_profiles'] }}
                        </small>
                    <hr>

                </div>
            </div>

        </div>
        <span id="bank"></span>

        <div class="row"
                style=" margin-top: 20px;
                margin-top: 15px;
                padding-top: 20px;
                padding-bottom: 20px;
                background-color: #f1f3ff;
                border-radius: 10px;">

            <div class="col-12">
                <p style="font-size: 1.2em;"><u>ขั้นตอนการสมัครเรียน</u></p>
            </div>

            <div class="col-12 text-center" >
                <img
                    class="bank img-fluid"
                    src="https://assets.dekthai-online.com/static/img/dekthai-online-bankacc.jpg">
            </div>
        </div>



        <!-- <app-recommend></app-recommend> -->
        <app-halloffame *ngIf="showHall"></app-halloffame>
    </div>
</div>
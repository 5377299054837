import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';

import { MycourseComponent } from './mycourse/mycourse.component';
import { NotifyComponent } from './notify/notify.component';
import { HistoryComponent } from './history/history.component';
import { IndexComponent } from './index/index.component';
import { ProfileComponent } from './profile/profile.component';
import { QuizComponent } from './quiz/quiz.component';
import { TestsComponent } from './tests/tests.component';
import { SubscriberComponent } from './subscriber/subscriber.component';



@NgModule({
  declarations: [MycourseComponent, NotifyComponent, HistoryComponent, IndexComponent, ProfileComponent, QuizComponent, TestsComponent, SubscriberComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule
  ]
})
export class DashboardModule { }

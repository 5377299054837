<div class="container-fuild">
    <div >

        <div class="row" style="margin-top: 20px;">


            <div class="col-12 col-sm-12 col-md-3 col-lg-3">

                <app-menu></app-menu>

            </div>

            <div class="col-12 col-sm-12 col-md-9 col-lg-9" style="min-height: 600px; border-left:1px solid #ccc;">
                <h2 class="mat-h2" style="border-bottom: 1px solid #ccc; padding-bottom: 15px;">
                    แก้ไขรายละเอียดคอร์สเรียน (แก้ไขแล้ว ให้กดบันทึกการแก้ไขด้านล่างด้วย)
                </h2>

                <div class="example-form">
                    <p>
                        <mat-form-field class="example-full-width">
                        <mat-label>ops_course_id (disabled)</mat-label>
                        <input [(ngModel)]="courses['ops_course_id']" matInput disabled >
                        </mat-form-field>
                    </p>

                    <div class="row" style="padding: 13px;">

                    <p style="margin-right: 25px;">
                        <span style="color: rgb(0 0 0 / 54%); font-size: 0.8em;">แสดงผลการค้นหา</span><br>
                        <mat-slide-toggle [(ngModel)]="courses['status']">Public</mat-slide-toggle>
                    </p>

                    <p style="margin-right: 25px;">
                        <span style="color: rgb(0 0 0 / 54%); font-size: 0.8em;">เว็บไซต์ที่แสดง</span><br>
                        <mat-checkbox class="example-margin"
                            [(ngModel)]="courses['site_apu']">APU</mat-checkbox>
                        &nbsp;&nbsp;
                        <mat-checkbox class="example-margin"
                            [(ngModel)]="courses['site_dekthai']" >DEKTHAI</mat-checkbox>
                    </p>

                    <p style="margin-right: 25px;">
                        <span style="color: rgb(0 0 0 / 54%); font-size: 0.8em;">ประเภทการเรียน</span><br>
                        <mat-checkbox class="example-margin"
                            [(ngModel)]="courses['zoom']">Zoom</mat-checkbox>
                        &nbsp;&nbsp;
                        <mat-checkbox class="example-margin"
                            [(ngModel)]="courses['online']" >Online</mat-checkbox>
                    </p>

                    </div>

                    <p>
                        <mat-form-field style="width: 45%; margin-right: 5%;">
                        <mat-label>วิชา</mat-label>
                            <mat-select [(ngModel)]="courses['subject']">
                                <mat-option
                                    *ngFor="let sub of subjectList"
                                    value="{{sub['id']}}"
                                >{{sub['subject']}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field style="width: 45%;">
                            <mat-label>ระดับชั้น (ใส่เรียงแบบไม่เว้นวรรค ป1ป2ป3ป4ป5ป6ม1ม2ม3ม4ม5ม6)</mat-label>
                                <input [(ngModel)]="courses['level']" matInput >
                            </mat-form-field>
                    </p>

                    <p style="display: none;">
                        <small>*ราคาแก้ไขเฉพาะที่แสดงที่หน้าเว็บเท่านั้น</small><br>
                        <mat-form-field style="width: 25%; margin-right: 5%;">
                            <mat-label>ราคาเรียนสด</mat-label>
                            <input [(ngModel)]="courses['price']" matInput >
                        </mat-form-field>
                        <mat-form-field style="width: 25%; margin-right: 5%;">
                            <mat-label>ราคาเรียน Zoom</mat-label>
                            <input [(ngModel)]="courses['price_zoom']" matInput >
                        </mat-form-field>
                        <mat-form-field style="width: 25%; margin-right: 5%;">
                            <mat-label>ราคาเรียน Online</mat-label>
                            <input [(ngModel)]="courses['price_online']" matInput >
                        </mat-form-field>
                    </p>


                    <p>
                        <mat-form-field class="example-full-width">
                        <mat-label>Course Name</mat-label>
                        <input [(ngModel)]="courses['name']" matInput >
                        </mat-form-field>
                    </p>


                    <p>
                        <mat-form-field class="example-full-width">
                        <mat-label>info</mat-label>
                        <textarea rows="5" [(ngModel)]="courses['info']" matInput></textarea>
                        </mat-form-field>
                    </p>

                    <p>Content</p>
                    <editor
                        apiKey="9da7pov8xgbuoz7uswe96p1s7kx5j6pdwll6li57zmc2ir06"
                        [(ngModel)]="courses['content']"
                        [init]="{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'lists link image paste help wordcount code media hr fullscreen preview'
                        ],
                        toolbar:
                        'undo redo |
                        formatselect |
                        bold italic forecolor backcolor |
                        alignleft aligncenter alignright alignjustify |
                        bullist numlist outdent indent |
                        image link media hr |
                        code preview fullscreen'
                        }"
                    ></editor>

                    <!-- style="min-height: 400px;" width="100%" height="auto"  -->
                    <p>
                        <img src="{{image_src}}" class="img-fluid img-thumbnail">
                    </p>

                    <p>
                        <small>ไฟล์ภาพ สัดส่วน 16:9 ขนาด 800px X 450px หรือ 1280px X 720px ขนาดไม่เกิน 1MB.</small><br>
                        <input
                          type="file"
                          name="profile_image"
                          (change)="getFileProfile($event.target.files)"><br>
                        <button
                            style="margin-top: 5px;"
                            mat-raised-button color="primary"
                            (click)="fileUpload('course_image')"
                        >Upload Course image</button>
                    </p>

                    <hr>
                    <button
                    style="margin-top: 5px;"
                    mat-raised-button color="accent"
                    (click)="submit()">
                        บันทึกการแก้ไข
                    </button>

                </div>


                    <form class="example-form" [formGroup]="courseForm" style="display: none;">
                    <p>
                        <mat-form-field class="example-full-width">
                        <mat-label>ops_course_id (disabled)</mat-label>
                        <input
                            formControlName="ops_course_id"
                            matInput >
                        </mat-form-field>
                    </p>

                    <!-- <p>
                        <mat-form-field class="example-full-width">
                          <mat-label>course type</mat-label>
                          <input
                            formControlName="course_type"
                            matInput>
                        </mat-form-field>
                    </p> -->

                    <p>
                        <mat-form-field class="example-full-width">
                          <mat-label>course name</mat-label>
                          <input
                            formControlName="name"
                            matInput>
                        </mat-form-field>
                    </p>

                    <p>
                        <mat-form-field class="example-full-width">
                            <mat-label>info (TEXT)</mat-label>
                            <textarea rows="5"
                              formControlName="info"
                              matInput>
                              </textarea>
                          </mat-form-field>
                    </p>

                    <strong>Zoomสด/Online</strong>
                    <p>
                        <mat-checkbox class="example-margin" >Zoom</mat-checkbox> &nbsp;&nbsp;
                        <mat-checkbox class="example-margin" >Online</mat-checkbox>
                    </p>

                    <!--
                    <p>
                        <mat-form-field class="example-full-width">
                          <mat-label>Content (HTML)</mat-label>
                          <textarea rows="10"
                            formControlName="content"
                            matInput>
                            </textarea>
                        </mat-form-field>
                    </p>
                    -->

                    <strong>Content</strong>
                    <hr>
                    <editor
                        apiKey="9da7pov8xgbuoz7uswe96p1s7kx5j6pdwll6li57zmc2ir06"
                        formControlName="content"
                        [init]="{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'lists link image paste help wordcount code media hr fullscreen preview'
                        ],
                        toolbar:
                        'undo redo |
                        formatselect |
                        bold italic forecolor backcolor |
                        alignleft aligncenter alignright alignjustify |
                        bullist numlist outdent indent |
                        image link media hr |
                        code preview fullscreen'
                        }"
                    ></editor>



                    <p>
                        <img src="{{image_src}}" class="img-fluid img-thumbnail">
                    </p>

                    <p>
                        <small>ไฟล์ ภาพขนาด 1280 x 720 ไม่เกิน 1MB.</small><br>
                        <input
                          type="file"
                          name="profile_image"
                          (change)="getFileProfile($event.target.files)"><br>
                        <button
                            style="margin-top: 5px;"
                            mat-raised-button color="primary"
                            (click)="fileUpload('course_image')"
                        >Upload Course image</button>
                    </p>

                    <hr>
                    <button
                            style="margin-top: 5px;"
                            mat-raised-button color="accent"
                            (click)="submit()"
                        >Update Course</button>
                    </form>
            </div>
        </div>
    </div>
</div>



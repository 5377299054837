import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConnService } from './conn.service';
import { URLAPI, HTTPOPTION } from './app.conn';
import { exit } from 'process';

@Injectable({
  providedIn: 'root'
})
export class AdminService {


  constructor(
    private _http: HttpClient,
    private _conn: ConnService
  ) { }


  async dekthaiCourse(form){
    console.warn('admin dekthai course');

    let api = 'v2/admin/getCourse';
    // let api = 'v2/admin/getCategory';

    let url = URLAPI + api;
    let authorization = this._conn.authorizationData();

    let httpOptions = {
      headers: new HttpHeaders({ 'content-type': 'application/json', 'authorization': authorization })
    };

    let response = await this._http
      .post(url, form, httpOptions )
      .pipe()
      .toPromise();

      let data  = response['data'];
      return data;
  }


  async dekthaiGetCategory(){
    console.warn('admin dekthai course');

    // let api = 'v2/admin/getCourse';
    let api = 'v2/admin/getCategory';
    let url = URLAPI + api;
    let authorization = this._conn.authorizationData();

    let httpOptions = {
      headers: new HttpHeaders({ 'content-type': 'application/json', 'authorization': authorization })
    };

    let response = await this._http
      .get(url, httpOptions )
      .pipe()
      .toPromise();
      let data  = response['data'];
      return data;
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { InstructorComponent } from './instructor/instructor.component';
import { CourselistsComponent } from './courselists/courselists.component';

const routes: Routes = [
  { path : 'instructor/:username', component : InstructorComponent },
//  {path : 'quizlists/:sid', component : QuizlistsComponent},
//  {path : 'quiz/:sid/:qid/:hash', component : QuizComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InstructorsRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { URLAPI, HTTPOPTION } from '../../app.conn';

@Component({
  selector: 'app-halloffame',
  templateUrl: './halloffame.component.html',
  styles: [
  ]
})
export class HalloffameComponent implements OnInit {

  constructor(
    private _http: HttpClient,
  ) { }

  URLAPI:string;
  HTTPOPTION:any;


  ngOnInit(): void {
    this.URLAPI = URLAPI;
    this.HTTPOPTION = HTTPOPTION;

    this.getHall();
  }

  halloffame:any;

  async getHall(){
    console.warn('getHall');
    let api = 'g/halloffame'
    let url = this.URLAPI+api;

    let response = await this._http
      .get(url, this.HTTPOPTION)
      .pipe()
      .toPromise();
      console.log(response);

      this.halloffame  = response['halloffame'];
      console.warn(this.halloffame);

  }

}

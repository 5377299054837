<app-carousel></app-carousel>

<div class="container-fuild">

    <!-- <app-textbox></app-textbox> -->

    <!-- <app-checkbox></app-checkbox> -->

    <app-result></app-result>

</div>
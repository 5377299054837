import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule  } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { CKEditorModule } from 'ckeditor4-angular';
import { CookieService } from 'ngx-cookie-service';
import { StorageServiceModule } from 'ngx-webstorage-service';

import { EditorModule } from '@tinymce/tinymce-angular';

// main router control
import { AppRoutingModule } from './app-routing.module';


// all module
import { BrowseModule } from './browse/browse.module';
import { InstructorsModule } from './instructors/instructors.module';
import { CourseModule } from './course/course.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { PortfolioModule } from './portfolio/portfolio.module';
import { ClassroomModule } from './classroom/classroom.module';
import { TeacherModule } from './teacher/teacher.module';
import { AdminModule } from './admin/admin.module';
import { SiteModule } from './site/site.module';

// component none module
import { AppComponent } from './app.component';

// import { HeaderComponent } from './site/header/header.component';
// import { FooterComponent } from './site/footer/footer.component';

// import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DemoMaterialModule } from './material.module';

@NgModule({
  declarations: [
    AppComponent,
    // HomeComponent,
    ContactComponent,
    LoginComponent,
    LogoutComponent,
    // HeaderComponent,
    // FooterComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    // CKEditorModule,
    EditorModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowseModule,
    InstructorsModule,
    CourseModule,
    DashboardModule,
    PortfolioModule,
    ClassroomModule,
    TeacherModule,
    AdminModule,
    StorageServiceModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    DemoMaterialModule,
    SiteModule
  ],
  providers: [ CookieService  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FormBuilder } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ConnService } from '../../conn.service';
import { URLAPI, HTTPOPTION } from '../../app.conn';

@Component({
  selector: 'app-courseedit',
  templateUrl: './courseedit.component.html',
  styleUrls: ['../index/index.component.css']
})
export class CourseeditComponent implements OnInit {

  courseForm = this._form.group({
    id: '',
    status: '',
    ops_course_id: '',
    course_type: '',
    price:'',

    name: '',
    info: '',
    content: '',
    course_image: '',

    daylimit: '',
    realtime: '',
    timelimit: '',
    timelimit_semi: ''
  });

  constructor(
    private _route: ActivatedRoute,
    private _http: HttpClient,
    private _form: FormBuilder,
    private _conn: ConnService
  ) { }

  ops_course_id:string;
  URLAPI:string;
  HTTPOPTION:any;
  authorizationData:any;

  course:any;
  courses = {'name':''};
  subjectList;

  ngOnInit(): void {
    this.URLAPI = URLAPI;
    this.HTTPOPTION = HTTPOPTION;
    this.authorizationData = this._conn.authorizationData();

    let sub = this._route.params.subscribe(params => {
      this.ops_course_id = params['cid'];
    });
    this.getCourse(this.ops_course_id);
  }

  async getCourse(ops_course_id){
    let api = 'g/course'
    let params = '/' + ops_course_id;
    let url = this.URLAPI+api+params;

    let response = await this._http
      .get(url, this.HTTPOPTION)
      .pipe()
      .toPromise();

      // console.warn(this.courseForm.value);

      let course = response['course'];
      this.course = course;
      this.courses = course;
      this.subjectList = course['subject_list'];
      console.warn(course);

      this.image_src = course['course_image'];

      this.courseForm = this._form.group({
        id:           course['id'],
        status:       course['status'],
        ops_course_id: course['ops_course_id'],
        course_type:  course['course_type'],
        price:        course['price'],

        name:         course['name'],
        info:         course['info'],
        content:      course['content'],
        course_image: course['course_image'],

        daylimit:     course['daylimit'],
        realtime:     course['realtime'],
        timelimit:    course['timelimit'],
        timelimit_semi: course['timelimit_semi'],
      });
  }

  yourFile: File;
  getFileProfile(fileInput) {
    this.yourFile = fileInput.item(0);
    console.log(this.yourFile);
 }


 async submit(){

    console.log(this.courses);

    let api = 'a2/updateCourse';
    let url = this.URLAPI + api;

    let formData = this.courses;
    console.warn(formData);

    let httpOptions = {
      headers: new HttpHeaders({ 'content-type': 'application/json', 'authorization': this.authorizationData })
    };

    //{ withCredentials: true, credentials: 'include' }
    let response = await this._http
      .post(url, formData, httpOptions )
      .pipe()
      .toPromise();

    // console.warn(response);

 }

 image_src;
 async fileUpload(perfix){
  // alert('fileUpload');

  let httpOptions = {
    headers: new HttpHeaders({'authorization': this.authorizationData})
  };

  let api = 'g/upload/'+perfix;
  let url = URLAPI + api;

  const formData: FormData = new FormData();
  formData.append('profile_image', this.yourFile, this.yourFile.name);

  let response = await this._http
    .post(url, formData, httpOptions )
    .pipe()
    .toPromise();

    let fileUpload = response;
    console.warn(fileUpload);

    let image_src = fileUpload['image_src'];
    this.image_src = image_src;
    // this.courseForm.value.course_image = image_src;
    this.courses['course_image'] = image_src;
 }

}

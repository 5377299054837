<div class="container-fuild">

    <div class="container">
        <div class="row" style="margin-top: 15px;">

            <div class="col-12 col-sm-4 col-md-3 col-lg-3 text-center">
                <img
                    src="{{ instructor?.profiles_image }}"
                    alt="profiles"
                    class="instructor_profiles_img">
            </div>

            <div class="col-12 col-sm-8 col-md-9 col-lg-9">

                    <div class="instructor_profiles">

                        <p style="font-size: +1.5em;">{{ instructor?.fullname }}</p>
                        <p>{{ instructor?.short_profiles }}</p>
                        <hr>
                            <p style="font-size: +1.2em;">ประวัติผู้สอน</p>
                            <p [innerHTML]="instructor?.background_profiles"></p>
                        </div>


<!-- Your share button code -->
<div style="width: 100%; text-align: right;"
    class="fb-like"
    data-href=""
    data-width=""
    data-layout="button"
    data-action="like"
    data-size="small"
    data-share="true"></div>
            </div>
        </div>

        <app-courselists></app-courselists>

        <app-halloffame *ngIf="showHall"></app-halloffame>

    </div>

</div>
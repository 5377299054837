import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IndexComponent } from './index/index.component';
import { QuizscoreComponent } from './quizscore/quizscore.component';
import { TestscoreComponent } from './testscore/testscore.component';


const routes: Routes = [
  {path : 'portfolio', component : IndexComponent},
//  {path : 'quizlists/:sid', component : QuizlistsComponent},
//  {path : 'quiz/:sid/:qid/:hash', component : QuizComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortfolioRoutingModule { }

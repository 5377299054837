import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { YouTubePlayerModule } from '@angular/youtube-player';

import { CourseRoutingModule } from './course-routing.module';
import { SiteModule } from './../site/site.module';

import { DetailComponent } from './detail/detail.component';
import { RecommendComponent } from './recommend/recommend.component';
import { PurchaseComponent } from './purchase/purchase.component';




@NgModule({
  declarations: [DetailComponent, RecommendComponent, PurchaseComponent],
  imports: [
    CommonModule,
    CourseRoutingModule,
    SiteModule
    // YouTubePlayerModule
  ]
})
export class CourseModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TeacherRoutingModule } from './teacher-routing.module';

import { IndexComponent } from './index/index.component';
import { ProfilesComponent } from './profiles/profiles.component';
import { CoursesComponent } from './courses/courses.component';
import { CategoryComponent } from './category/category.component';
import { MenuComponent } from './menu/menu.component';
import { QuizComponent } from './quiz/quiz.component';
import { QuizdetailComponent } from './quizdetail/quizdetail.component';

import { DemoMaterialModule } from './../material.module';


@NgModule({
  declarations: [IndexComponent, ProfilesComponent, CoursesComponent, CategoryComponent, MenuComponent, QuizComponent, QuizdetailComponent],
  imports: [
    CommonModule,
    TeacherRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DemoMaterialModule
  ]
})
export class TeacherModule {
  constructor() {

  }
}

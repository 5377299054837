import { Component, OnInit } from '@angular/core';

import { retest, islam, lastonline, lastQuiz } from '../../../assets/history_data';


@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styles: [
  ]
})
export class HistoryComponent implements OnInit {

  retest:any= retest;
  islam:any= islam;
  lastonline:any= lastonline;
  lastQuiz:any= lastQuiz;

  constructor() { }

  ngOnInit(): void {
  }

}

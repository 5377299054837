<!-- {{ops_course_id}} -->
<div class="container-fuild">
    <div class="container">
        <div class="row" style="margin-top: 15px;">
            <div class="col-12">
                <h4>ขั้นตอนการสมัครเรียน</h4><hr>
            </div>
            <div class="col-12 text-center" >
                    <img
                        style="max-width: 600px;"
                        class="img-fluid"
                        src="https://assets.dekthai-online.com/static/img/dekthai-online-bankacc.jpg">
            </div>





        </div>
    </div>
</div>
<div class="container-fuild">
    <div>

        <div class="row" style="margin-top: 20px;">
            <div class="col-12 col-sm-12 col-md-3 col-lg-3">

                <app-menu></app-menu>

            </div>

            <div class="col-12 col-sm-12 col-md-9 col-lg-9" style="min-height: 600px; border-left:1px solid #ccc;">
                <h2 class="mat-h2" style="border-bottom: 1px solid #ccc; padding-bottom: 15px;">
                    เพิ่มบทความ
                </h2>

                <table class="table">
                    <tr>
                        <th>Name</th>
                        <th>Posts</th>
                        <th>Public</th>
                        <th>pin</th>
                    </tr>
                    <tbody>
                        <tr *ngFor="let blog of blogs;">
                        <td><a routerLink="/admin/blogedit/{{blog['id']}}">{{blog['name']}}</a></td>
                        <td>{{blog['type']}}
                            <mat-slide-toggle [(ngModel)]="blog['type']"
                                (change)="update(blog, $event)"
                            >
                                <!-- <span *ngIf="blog['status']">แสดง</span> -->
                            </mat-slide-toggle>

                        </td>
                        <td>
                            <mat-slide-toggle [(ngModel)]="blog['status']"
                                (change)="update(blog, $event)"
                            >
                                <!-- <span *ngIf="blog['status']">แสดง</span> -->
                            </mat-slide-toggle>
                        </td>
                        <td>
                            <mat-slide-toggle [(ngModel)]="blog['pin']"
                                (change)="update(blog, $event)"
                            >
                                <!-- <span *ngIf="blog['status']">แสดง</span> -->
                            </mat-slide-toggle>
                        </td>
                        </tr>
                    </tbody>

                </table>



            </div>

        </div>
    </div>
</div>



<hr>

    <div class="row search-subject-zoom" >
      <div class="col">
          <p style="font-size: 1.2em;">รุ่นพี่คนเก่ง</p>
      </div>
  </div>

  <div class="row" style="background-color: #fff;">
    <div class="col-6 col-sm-6 col-md-6 col-lg-3 text-center" *ngFor="let hall of halloffame" style="margin-bottom: 20px;">
        <img class="img-thumbnail" src="{{hall}}" width="98%">
    </div>
</div>
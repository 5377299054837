<h5 style="text-align: right; margin-top: 45px;">คอร์สเรียนของฉัน</h5>
<hr>

<div class="row">

    <div class="col-12 col-sm-12 col-md-6 col-lg-4" *ngFor="let course of courseLists">

        <div class="browse_item"
        style="
        margin-top: 5px;
        background-color: #eee;
        box-sizing: border-box;
        min-height: 400px;
        width: auto;
        overflow: hidden;
        border-radius: 8px;
        box-shadow: 0 0 1px rgb(90, 90, 90);
        transition: all 200ms ease-in-out 0s;
        will-change: transform;
        margin-bottom: 25px;"
      >

        <img class="browse_item_cover"
            src="https://assets.dekthai-online.com/banner-1200x720-course1.jpg"
            width="100%">

        <div class="browse_item_body"
            style="width: 100%;
                padding-left: 15px;
                padding-right: 15px;
                min-height: 200px;
                background-color: #fff;"
            >
            <span
                style="font-size: 0.8em; font-weight: normal; margin-top: 20px; color:#3c832a;">
                    Online 100%
            </span>

            <h5>{{course['course_name']}}</h5>

            <small>เรียนแล้ว 25%
            <div class="progress" style="height: 3px;">
                <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>

                <span class="badge badge-info" style="font-weight: normal;">เหลือเวลา</span> 10 ชั่วโมง 12 นาที
                <span class="badge badge-warning" style="font-weight: normal;">exp</span> {{course['expire_date']}}
            </small>

            <div style="
                  box-sizing: border-box;
                  min-width: 0px;
                  margin-left: 0px;
                  align-items: center;
                  text-align: center;
                  margin-top: 15px;">

            <a class="btn btn-danger btn-sm btnroom" href="#" *ngFor="let zoom of course['zoom']">
                <!-- <img
                src="https://assets.dekthai-online.com/Ppu-400x400.jpg"
                alt="profiles"
                style="
                box-sizing: border-box;
                min-width: 10px;
                width: 28px;
                margin: 1px;
                height: auto;
                border-radius: 100%;
                box-shadow: rgb(152 152 152) 0px 0px 2px 1px;"> -->
                เข้าห้องเรียน Zoom<br>{{zoom['name']}}
            </a>


          </div>

        </div>

        <div class="browse_item_footer"
            style="box-sizing: border-box;
                min-width: 1px;
                width: 100%;
                padding: 0px;
                min-height: 45px;
                text-align: center !important;;
                align-items: center;
                border-top: 1px solid rgba(72, 72, 72, 0.3);
                background-color: #ffffff;
                padding-top: 5px;">

            <a href="portfolio"  class="btn btn-info btn-sm btnroom">Portfolio</a>
            <a href="course/A001"  class="btn btn-success btn-sm btnroom">เข้าห้องเรียน Online</a>

        </div>
      </div>        <!-- end browse_item -->

    </div>


</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { URLAPI, HTTPOPTION } from '../../app.conn';

@Component({
  selector: 'app-courselists',
  templateUrl: './courselists.component.html',
  styleUrls: ['../instructor/instructor.component.css']
})
export class CourselistsComponent implements OnInit {

  constructor(
    private _http: HttpClient,
    private _route: ActivatedRoute
  ) { }

  URLAPI:string;
  HTTPOPTION:any;

  username:string;
  course:any;
  category:any;
  categorynew:any;

  ngOnInit(): void {
    //console.log(instructor);
    this.URLAPI = URLAPI;
    this.HTTPOPTION = HTTPOPTION;

    let sub = this._route.params.subscribe(params => {
      this.username = params['username'];
    });
    // console.warn(this.username);
    this.getCourse();
  }

  async getCourse(){
    // console.warn('instructor: '+this.username);

    let api = 'g/InstructorCourse'
    let params = '/'+this.username;
    let url = this.URLAPI+api+params;

    let response = await this._http
      .get(url, this.HTTPOPTION)
      .pipe()
      .toPromise();
      // console.log(response);

      this.categorynew   = response['category'];
      //this.course     = data['course'];



  }

}

import { Component, OnInit } from '@angular/core';

import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
// import { MatDialog } from '@angular/material/dialog';

import { ConnService } from '../../conn.service';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { URLAPI, HTTPOPTION } from '../../app.conn';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['../index/index.component.css']
})
export class QuizComponent implements OnInit {

  formChapter = new FormControl();
  options: string[] = [];
  optionsConst: string[] = [];
  filteredOptions: Observable<string[]>;

  URLAPI:string;
  HTTPOPTION:any;
  authorizationData:any;
  fullname:any;
  username:any;
  ops_teacher_name:any;

  chapterlists:any;
  chapterId:any = 1352;
  chapterSelected:any = 'DEKTHAI-ChapterTest';
  addQuizShow = true;
  loading = false;

  constructor(
    private _http: HttpClient,
    private _conn: ConnService
    // public _dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.URLAPI = URLAPI;
    this.HTTPOPTION = HTTPOPTION;
    this.authorizationData = this._conn.authorizationData();

    let user = this._conn.simpleUser();
    console.warn(user);
    this.fullname = user['fullname'];
    this.username = user['user'];
    this.ops_teacher_name = user['ops_teacher_name'];
    this.getChater();
  }

  async getChater(){
    // t2/instructorChapter
    // console.log('instructorChapter');

    let api = 't2/instructorChapter';
    let url = this.URLAPI + api;

    let httpOptions = {
      headers: new HttpHeaders({ 'content-type': 'application/json', 'authorization': this.authorizationData })
    };

    //{ withCredentials: true, credentials: 'include' }
    let response = await this._http
      .get(url, this.HTTPOPTION )
      .pipe()
      .toPromise();

    let data = response['data'];
    let chapterlists = data['chapterlists'];
    this.chapterlists = chapterlists;

    let options: string[] = [];
    chapterlists.forEach(obj => {
    //  console.log(obj);
     options.push(obj['name']);

    });
    // console.warn(options);
    this.optionsConst = options
    this.options = options;

    this.filteredOptions = this.formChapter.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  showChapterQuiz(){2
    //alert('viewQuiz');
    this.loading = true;
    let form = this.formChapter;
    let chapterSelected = form.value;

    console.warn(chapterSelected);

    this.chapterSelected = chapterSelected;

    let chapterlists = this.chapterlists;
    let chapterId:number;
    chapterlists.forEach(obj => {
      //  console.log(obj);
       //obj['name']
       if(chapterSelected === obj['name']){
        chapterId = obj['id'];
       }
    });
    this.chapterId = chapterId;

    // console.warn('chapter_id: '+chapterId);
    this.getQuizByChapter(chapterId);
  }

  quizTable = true;
  // quizLists:any = [{"id":"1054","chapter_id":"1352","name":"DEKTHAI-ChapterTest","description":"T001 DEKTHAI FREE QUIZ -03","teacher":"พี่ปั้นจั่น ปัญญธร วรดี","flag":"0","tscore_min":"0","tscore_max":"0","max_pts":"100","max_student":"3","note":"","created_time":"2021-07-05 18:31:30","last_update":"0000-00-00 00:00:00","seq":3,"flag_c":false},{"id":"1053","chapter_id":"1352","name":"DEKTHAI-ChapterTest","description":"T001 DEKTHAI FREE QUIZ -02","teacher":"พี่ปั้นจั่น ปัญญธร วรดี","flag":"0","tscore_min":"0","tscore_max":"0","max_pts":"100","max_student":"3","note":"","created_time":"2021-07-05 18:20:00","last_update":"0000-00-00 00:00:00","seq":2,"flag_c":false},{"id":"1052","chapter_id":"1352","name":"DEKTHAI-ChapterTest","description":"T001 DEKTHAI FREE QUIZ-01","teacher":"พี่ปั้นจั่น ปัญญธร วรดี","flag":"0","tscore_min":"0","tscore_max":"0","max_pts":"100","max_student":"3","note":"","created_time":"2021-07-05 18:12:38","last_update":"0000-00-00 00:00:00","seq":1,"flag_c":false}];
  quizLists:any = [];
  async getQuizByChapter(chapterId){
    console.log('getQuizByChapter');
    let api = 't2/instructorChapterQuiz/'+chapterId;
    let url = this.URLAPI + api;

    //{ withCredentials: true, credentials: 'include' }
    let response = await this._http
      .get(url, this.HTTPOPTION )
      .pipe()
      .toPromise();

      this.quizTable = true;
      this.addQuizShow = false;
      this.loading = false;

      let data = response['data'];
      let quizLists = data['quizists'];
      this.quizLists = quizLists;
      console.warn(response);


  }

  slideSwitch(id, ev){
    // console.log('slideSwitch: '+ id);

    let check = ev['checked'];
    this.quizChangeFlag(id, check);

    let quizLists = this.quizLists;

    let tmp: any[] = [];
    if(check){
      quizLists.forEach(obj => {
        if(obj['id'] != id){
          obj['flag'] = 0;
          obj['flag_c'] = false;
        }
        tmp.push(obj);
      });
      // console.log(tmp)
      this.quizLists = tmp;
    }

  }

  async quizChangeFlag(id, flag){
    console.log('quizChangeFlag');
    let api = 't2/instructorChapterQuizFlag';
    let url = this.URLAPI + api;
    let chapterId = this.chapterId;

    let dataPost = {"chapter_id": chapterId, "quiz_id": id, "flag": flag};

    let response = await this._http
        .post(url, dataPost, this.HTTPOPTION )
        .pipe()
        .toPromise();

  }

  // formQuizName        = new FormControl();
  formQuizDescription = new FormControl();

  async createQuiz(){

    this.loading = true;

    let chapterId = this.chapterId;
    // let quizName  = this.formQuizName.value;
    let quizDescription  = this.formQuizDescription.value;

    let chapterName = this.chapterSelected;
    let dataPost = {"chapter_id": chapterId, "quiz_description": quizDescription, "chapter_name": chapterName};

    console.warn(dataPost);

    console.log('createQuiz');
    let api = 't2/instructorChapterQuizCreate';
    let url = this.URLAPI + api;

    let response = await this._http
        .post(url, dataPost, this.HTTPOPTION )
        .pipe()
        .toPromise();

        this.loading = false;
        this.showChapterQuiz();
        this.formQuizDescription = new FormControl();
  }

  private _filter(value: string): string[] {
    // console.log(value);
    const filterValue = value.toLowerCase();
    let options = this.optionsConst;

    var newOptions:string[] = [];

    options.forEach(obj => {
       let seaText:String;
       seaText = obj.toLowerCase();
       let position = seaText.search(filterValue);
       if(position >= 0){
         newOptions.push( obj );
       }
      });
    // console.log(newOptions);

    this.options = newOptions;
    return this.options;
    // return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
}

<div style="
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #fff6da;
    padding: 10px;">

<p><i class="fas fa-bell"></i> ผล Quiz ล่าสุด</p>
<table class="table">
    <thead>
      <tr>
        <th class="text-center info">วันที่</th>
        <th class="text-center info">รายละเอียด</th>
        <th class="text-center info">T-score</th>
    </tr>
    </thead>
</table>
</div>

<div  style="
margin-bottom: 10px;
border-radius: 10px;
background-color: #ccffdb;
padding: 10px;">
<p><i class="fas fa-bell"></i> ผลการสอบล่าสุด</p>
<table class="table table-sm">
    <thead>
      <tr>
        <th scope="col">วันที่สอบ</th>
        <th scope="col">บทเรียน</th>
        <th scope="col">คะแนนนักเรียน/เฉลี่ยห้อง</th>
        <th scope="col">รายละเอียด</th>
    </tr>
    </thead>
</table>
</div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IndexComponent } from './index/index.component';
import { ProfilesComponent } from './profiles/profiles.component';
import { CoursesComponent } from './courses/courses.component';
import { CategoryComponent } from './category/category.component';
import { QuizComponent } from './quiz/quiz.component';
import { QuizdetailComponent } from './quizdetail/quizdetail.component';


const routes: Routes = [
  { path : 'teacher', component : QuizComponent },
  { path : 'teacher/profile', component : ProfilesComponent },
  { path : 'teacher/course', component : CoursesComponent },
  { path : 'teacher/category', component : CoursesComponent },
  { path : 'teacher/quiz', component : QuizComponent },
  { path : 'teacher/quiz/:qid', component : QuizdetailComponent },

//  {path : 'quizlists/:sid', component : QuizlistsComponent},
//  {path : 'quiz/:sid/:qid/:hash', component : QuizComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TeacherRoutingModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RegisterComponent } from './register/register.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { BankComponent } from './bank/bank.component';
import { HalloffameComponent } from './halloffame/halloffame.component';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, RegisterComponent, NotfoundComponent, BankComponent, HalloffameComponent],
  imports: [
    CommonModule
  ],
  exports: [
    FooterComponent,
    HalloffameComponent
  ]
})
export class SiteModule { }
